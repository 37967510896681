import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Lottie from 'lottie-react';
import Sound from 'react-sound';
import confetti from '../../assets/animations/top-down-confetti.json';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { SvgIcon, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as CheckIcon } from '../../assets/icons/Check.svg';
import FullScreenDialog, { FullScreenDialogProps } from '../FullScreenDialog';

import * as ProfileService from '../../api/profileService';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(15),
    overflow: 'hidden',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  vip: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#ffae23',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fillRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.main,
  },
  timestamp: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  box: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  close: {
    marginLeft: 'auto',
  },
  closeIcon: {
    color: theme.palette.common.black,
  },
}));

type Props = Pick<FullScreenDialogProps, 'open' | 'onClose'> & {
  rewardTitle: string;
};

export default function RewardSuccessRedemptionDialog({
  rewardTitle,
  onClose,
  open,
}: Props) {
  const classes = useStyles();
  const [profile, setProfile] = useState<ProfileService.Profile | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      const res = await ProfileService.get();
      setProfile(res);
      setIsPlaying(true);
    };

    fetchProfile();
  }, []);

  if (!profile) {
    return null;
  }

  return (
    <FullScreenDialog open={open}>
      {isPlaying && (
        <Lottie
          animationData={confetti}
          loop={isPlaying}
          autoplay={false}
          style={{ left: 0, right: 0, position: 'absolute' }}
        />
      )}
      <Sound
        url={'../sound/tada.mp3'}
        playStatus={isPlaying ? 'PLAYING' : 'STOPPED'}
        onFinishedPlaying={() => setIsPlaying(false)}
      />
      <div className={classes.box}>
        <div className={classes.close}>
          <IconButton
            onClick={() => {
              if (onClose) {
                onClose({}, 'backdropClick');
              }
            }}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </div>
      <div className={classes.cardContainer}>
        <Typography variant="h4" className={classes.title} gutterBottom>
          {profile.firstName} {profile.lastName}
        </Typography>
        <div className={classes.fillRoot}>
          <SvgIcon
            component={CheckIcon}
            color="primary"
            className={classes.icon}
            viewBox={'0 0 30 30'}
            fontSize="large"
          />
        </div>
        <Typography variant="h4" className={classes.title} gutterBottom>
          Reward Redeemed!
        </Typography>
        <Typography variant="h5" gutterBottom>
          {rewardTitle}
        </Typography>
        <Typography variant="body1" className={classes.timestamp} gutterBottom>
          {moment().format('llll')}
        </Typography>
      </div>
    </FullScreenDialog>
  );
}
