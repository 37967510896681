/**
 * @file Manages the navigation storage in local storage
 */

export type Nav = {
  menuNav: string;
};

/**
 * Get the current Nav
 */
export function get(): Nav | null {
  const nav = window.localStorage.getItem('nav');

  if (nav) {
    return JSON.parse(nav);
  }

  return null;
}

/**
 * Set the Nav
 *
 * @param nav
 */
export function set(nav: Nav) {
  window.localStorage.setItem('nav', JSON.stringify(nav));
}

/**
 * Remove the nav
 */
export function remove() {
  window.localStorage.removeItem('nav');
}
