import { client } from './index';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export type PaginationOptions = {
  page: number;
  per: number;
};

/**
 * Request Wrapper with default success/error actions
 */
export default async function request<T>(options: AxiosRequestConfig) {
  const onSuccess = function (response: AxiosResponse) {
    return response.data;
  };

  const onError = function (error: AxiosError) {
    // Commented out debug logging
    // console.error('Request Failed:', error.config);

    // if (error.response) {
    //   // Request was made but server responded with something
    //   // other than 2xx
    //   console.error('Status:', error.response.status);
    //   console.error('Data:', error.response.data);
    //   console.error('Headers:', error.response.headers);
    // } else {
    //   // Something else happened while setting up the request
    //   // triggered the error
    //   console.error('Error Message:', error.message);
    // }

    return Promise.reject(error.response || error.message);
  };

  return client.request(options).then<T>(onSuccess).catch(onError);
}
