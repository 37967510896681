import React, { useState, useEffect } from 'react';
import GoogleMapReact, { Props as GoogleMapReactProps } from 'google-map-react';
import SvgIcon from '@material-ui/core/SvgIcon';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import * as StoreService from '../../../api/storeService';

import { PointMarker } from '../Marker';
import Paper from '../../Paper';
import { ReactComponent as Phone } from '../../../assets/icons/Phone.svg';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  callContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(),
  },
  storeHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  map: {
    marginBottom: theme.spacing(2),
    height: 400,
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 350,
  },
  storeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  storeHeaderDialog: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(),
  },
  grow: {
    flexGrow: 1,
  },
}));

type Props = GoogleMapReactProps & {
  marker: {
    lat: number;
    lng: number;
  };
  markerType?: string;
  storeList: StoreService.Store[];
  fromDialog?: boolean;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function StoreMap({
  marker,
  markerType,
  storeList,
  fromDialog,
  ...props
}: Props) {
  const classes = useStyles();
  const [selectedStore, setSelectedStore] = useState<
    StoreService.Store | undefined
  >(undefined);
  const DEFAULT_CENTER = {
    lat: marker.lat,
    lng: marker.lng,
  };
  const DEFAULT_ZOOM = storeList.length === 1 ? 14 : 8;
  // const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>(
  //   DEFAULT_CENTER
  // );
  const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM);
  const [isLinkClick, setIsLinkClick] = useState(false);

  useEffect(() => {
    if (storeList.length > 0) {
      setSelectedStore(storeList[0]);
    }
  }, [storeList]);

  useEffect(() => {
    if (isLinkClick) {
      setIsLinkClick(false);
    }
  }, [isLinkClick]);

  const handleStoreCall = () => {
    if (selectedStore && selectedStore.phone) {
      const a = document.createElement('a');
      a.href = 'tel://' + selectedStore.phone;
      document.body.appendChild(a);
      a.click();
      setIsLinkClick(true);
      if (a.parentNode) {
        a.parentNode.removeChild(a);
      }
    }
  };

  const handleMapChange = (e: any) => {
    setZoomLevel(e.zoom);
  };

  if (!(window as any).google) {
    return null;
  }

  if (!selectedStore) {
    return null;
  }

  return (
    <Paper className={classes.cardContainer}>
      <div className={classes.map}>
        <GoogleMapReact
          center={
            selectedStore
              ? { lat: selectedStore.coords.lat, lng: selectedStore.coords.lng }
              : DEFAULT_CENTER
          }
          zoom={zoomLevel}
          googleMapLoader={() => Promise.resolve((window as any).google.maps)}
          onChange={(e) => handleMapChange(e)}
          {...props}
        >
          {storeList.map((store, index) => (
            <PointMarker
              key={index}
              lat={store.coords.lat}
              lng={store.coords.lng}
              selected={store.id === selectedStore.id}
              handleStoreMarkerPress={() => setSelectedStore(store)}
            />
          ))}
        </GoogleMapReact>
      </div>
      {!fromDialog ? (
        <div className={classes.storeHeader}>
          <Typography variant="body1">{selectedStore.name}</Typography>
          <Typography variant="body1">{selectedStore.address}</Typography>
        </div>
      ) : (
        <div className={classes.storeContainer}>
          <div className={classes.storeHeaderDialog}>
            <Typography variant="body1">{selectedStore.name}</Typography>
            <Typography variant="body1">{selectedStore.address}</Typography>
          </div>
          {!isLinkClick &&
            selectedStore &&
            selectedStore.phone &&
            selectedStore.phone.length > 0 && (
              <IconButton
                className={classes.callContainer}
                onClick={() => handleStoreCall()}
              >
                <SvgIcon component={Phone} viewBox="0 0 30 30" />
              </IconButton>
            )}
        </div>
      )}
    </Paper>
  );
}
