import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DialogPaper from '../DialogPaper';

type ConfirmRedemptionDialogProps = Pick<DialogProps, 'open' | 'onClose'> & {
  onCancel: () => void;
  onConfirm: () => void;
};

const useCDStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  subtext: {
    color: '#868686',
    padding: theme.spacing(),
  },
  remark: {
    padding: theme.spacing(),
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  buttonContainer: {
    width: 120,
    margin: theme.spacing(2),
  },
}));

const ConfirmRedemptionDialog = ({
  open,
  onClose,
  onCancel,
  onConfirm,
}: ConfirmRedemptionDialogProps) => {
  const classes = useCDStyles();
  return (
    <Dialog open={open} onClose={onClose} PaperComponent={DialogPaper}>
      <div className={classes.cardContainer}>
        <Typography variant="h4" className={classes.title} gutterBottom>
          Confirm Redemption
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.subtext}
          gutterBottom
        >
          IS STAFF PRESENT?
        </Typography>
        <Typography variant="body1" className={classes.remark} gutterBottom>
          Please make sure you are with a staff member and are ready to claim
          your reward. This action cannot be undone.
        </Typography>
        <div className={classes.actionContainer}>
          <Button
            onClick={onCancel}
            className={classes.buttonContainer}
            variant="contained"
          >
            CANCEL
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            className={classes.buttonContainer}
          >
            CONFIRM
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    width: 150,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  subtext: {
    color: '#6E6E6E',
    textAlign: 'center',
    padding: theme.spacing(),
  },
  avatar: {
    height: 80,
    width: 80,
    boxShadow: '0 2px 10px rgba(199, 199, 199, 0.5)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  remindText: {
    textAlign: 'center',
  },
}));

type Props = Pick<DialogProps, 'open' | 'onClose'> & {
  rewardTitle: string;
  merchantLogo?: string;
  confirmRedemption: () => void;
};

export default function RewardRedemptionDialog({
  rewardTitle,
  merchantLogo,
  onClose,
  open,
  confirmRedemption,
}: Props) {
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState(false);

  return (
    <Dialog open={open} onClose={onClose} PaperComponent={DialogPaper}>
      <div className={classes.cardContainer}>
        <Avatar
          alt="merchantLogo"
          src={merchantLogo}
          className={classes.avatar}
        />
        <Typography variant="h5" className={classes.title} gutterBottom>
          {rewardTitle}
        </Typography>
        <Button
          color="primary"
          className={classes.buttonContainer}
          onClick={() => setConfirmDialog(true)}
        >
          REDEEM NOW
        </Button>
        <Typography variant="body1" className={classes.remindText} gutterBottom>
          Claim your reward by showing this page to staff when you tap the
          "Redeem Now" button. Staff must be present.
        </Typography>
        <Typography variant="body1" className={classes.remindText} gutterBottom>
          A success page will be shown and the reward will be removed after you
          claim your reward.
        </Typography>
      </div>
      <ConfirmRedemptionDialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={confirmRedemption}
      />
    </Dialog>
  );
}
