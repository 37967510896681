import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';

import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import * as ProfileService from '../../../api/profileService';
import useAuthContext from '../../../hooks/useAuthContext';
import useNavContext from '../../../hooks/useNavContext';
import useDeepLinkContext from '../../../hooks/useDeepLinkContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  appbar: {
    boxShadow: '0 0 20px #e4e4e4',
  },
}));

export default function AuthLayout({ children }: React.PropsWithChildren<{}>) {
  const classes = useStyles();
  const [profile, setProfile] = useState<ProfileService.Profile | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const auth = useAuthContext();
  const nav = useNavContext();
  const history = useHistory();
  const deeplink = useDeepLinkContext();

  const handleLogOut = async () => {
    handleMenuClose();
    if (auth && nav) {
      auth.removeSession();
      nav.removeNav();
      history.replace({});
      history.push(`/`);
    }
    if (deeplink) {
      deeplink.removeDeeplink();
    }
    try {
      googleLogout();
      console.log('user logout');
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await ProfileService.get();

        setProfile(res);
      } catch (e) {
        console.log('Error: ', e);
      }
    };

    if (auth && auth.session && auth.session.token.length > 0) {
      fetch();
    }
  }, [auth]);

  const renderMenu = (
    <Drawer anchor="top" open={isMenuOpen} onClose={handleMenuClose}>
      <List>
        <ListItem
          button
          key="home"
          disabled={nav?.nav?.menuNav === 'home'}
          onClick={() => {
            handleMenuClose();
            history.push('/');
            nav?.setNav({
              menuNav: 'home',
            });
          }}
        >
          <ListItemText primary="HOME" />
        </ListItem>
        <ListItem
          button
          key="profile"
          onClick={() => {
            handleMenuClose();
            history.push('/profile');
            nav?.setNav({
              menuNav: 'profile',
            });
          }}
          disabled={nav?.nav?.menuNav === 'profile'}
        >
          <ListItemText primary="YOUR PROFILE" />
        </ListItem>
        <ListItem
          button
          key="about"
          onClick={() => {
            handleMenuClose();
            history.push('/about');
            nav?.setNav({
              menuNav: 'about',
            });
          }}
          disabled={nav?.nav?.menuNav === 'about'}
        >
          <ListItemText primary="ABOUT" />
        </ListItem>
        <ListItem button key="logout" onClick={handleLogOut}>
          <ListItemText primary="LOGOUT" />
        </ListItem>
      </List>
    </Drawer>
  );

  if (!profile) {
    return null;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" color="secondary" className={classes.appbar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {profile.firstName} {profile.lastName}
          </Typography>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {children}
    </div>
  );
}
