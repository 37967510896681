import React from 'react';
import moment from 'moment';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import DatePicker from '../FormikAdapter/DatePicker';
import * as Yup from 'yup';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DialogPaper from '../DialogPaper';

import * as ProfileService from '../../api/profileService';

export interface Values {
  dob: moment.Moment | null;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    width: 100,
    margin: theme.spacing(),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  toolbar: {
    display: 'flex',
    paddingLeft: 12,
    paddingRight: 12,
  },
  box: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  close: {
    marginRight: 'auto',
  },
  actions: {
    marginLeft: 'auto',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
  },
  closeIcon: {
    color: theme.palette.common.black,
  },
}));

type Props = Pick<DialogProps, 'open' | 'onClose'> & {
  profile: ProfileService.Profile;
  confirmUpdate: (status: boolean) => void;
};

export default function EditDOBDialog({
  profile,
  onClose,
  open,
  confirmUpdate,
}: Props) {
  const classes = useStyles();
  const ValidationSchema = Yup.object().shape({
    dob: Yup.string().required('Required').nullable(),
  });

  const initialValues: Values = {
    dob:
      !profile.dob || profile.dob === -62135596800
        ? null
        : moment.unix(profile.dob),
  };

  const handleSubmit = async (
    values: Values,
    { setFieldError, setSubmitting }: FormikHelpers<Values>
  ) => {
    if (values.dob) {
      try {
        await ProfileService.update(
          profile.gender,
          values.dob.format('YYYY-MM-DD'),
          profile.firstName,
          profile.lastName,
          profile.email,
          profile.mobNum,
          profile.alt_email,
          profile.company
        );
        confirmUpdate(true);
        setSubmitting(false);
      } catch (e: any) {
        console.log(e);
        e.data.forEach((err: any) => {
          setFieldError(err.meta.attribute, err.detail);
        });
        setSubmitting(false);
      }
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperComponent={DialogPaper}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={false}
      >
        {({ isSubmitting, dirty }) => {
          return (
            <>
              <Form noValidate>
                <AppBar className={classes.appBar} position="sticky">
                  <Toolbar className={classes.toolbar}>
                    <div className={classes.box}>
                      <div className={classes.close}>
                        <IconButton onClick={handleClose}>
                          <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                      </div>
                    </div>
                    <div className={classes.box}>
                      <Typography variant="h6" className={classes.title}>
                        Edit Birthday
                      </Typography>
                    </div>
                    <div className={classes.box}>
                      <div className={classes.actions}></div>
                    </div>
                  </Toolbar>
                </AppBar>
                <Container maxWidth="md">
                  <div className={classes.cardContainer}>
                    <Field
                      component={DatePicker}
                      className={classes.textField}
                      name="dob"
                      label="Birthday"
                      format="DD/MM/YYYY"
                      fullWidth
                      initvalue={
                        !profile.dob || profile.dob === -62135596800
                          ? null
                          : moment.unix(profile.dob)
                      }
                    />
                    <div className={classes.actionContainer}>
                      <Button
                        onClick={() => {
                          if (onClose) {
                            onClose({}, 'backdropClick');
                          }
                        }}
                        className={classes.buttonContainer}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        className={classes.buttonContainer}
                        disabled={isSubmitting || !dirty}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Container>
              </Form>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
}
