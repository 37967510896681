import request from './request';
import deserialize from './deserialize';

export type RewardCard = {
  id: string;
  expiresAt: number;
  gift: boolean;
  merchantName: string;
  receivedAt: number;
  redeemedAt: number;
  remind: false;
  source: string;
  status: string;
  title: string;
  reward: {
    type: 'rewards';
    id: string;
  };
};

export function listMerchantCollectedRewardCards(merchantId: string) {
  return request<RewardCard[]>({
    url: `/api/v3/merchants/${merchantId}/reward_cards?filter[status][eq]=collected`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function listMerchantRedemeedRewardCards(merchantId: string) {
  return request<RewardCard[]>({
    url: `/api/v3/merchants/${merchantId}/reward_cards?filter[status][eq]=redeemed`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function listMerchantExpiredRewardCards(merchantId: string) {
  return request<RewardCard[]>({
    url: `/api/v3/merchants/${merchantId}/reward_cards?filter[status][eq]=expired`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function redeem(rewardCardId: string, pinCode: string) {
  return request<RewardCard>({
    url: `/api/v3/reward_cards/${rewardCardId}/redeem`,
    method: 'PATCH',
    transformResponse: deserialize,
    data: {
      data: {
        attributes: {
          pin: pinCode,
        },
      },
    },
  });
}

export function get(rewardCardId: string) {
  return request<RewardCard>({
    url: `/api/v3/reward_cards/${rewardCardId}`,
    method: 'GET',
    transformResponse: deserialize,
  });
}
