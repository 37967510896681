import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

export default function Spinner() {
  return (
    <Fade
      in={true}
      style={{
        transitionDelay: '800ms',
      }}
      unmountOnExit
    >
      <CircularProgress color="primary" />
    </Fade>
  );
}
