import React from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as RewardCardService from '../../api/rewardCardService';

import Paper from '../Paper';
import { ReactComponent as CheckIcon } from '../../assets/icons/Check.svg';

const useRewardStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 350,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    color: theme.palette.common.white,
  },
  fillRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.text.secondary,
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    backgroundColor: theme.palette.text.secondary,
    marginRight: theme.spacing(3),
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  expiredRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(3),
  },
}));

export function RewardContainer({
  title,
  redeemedAt,
  status,
  expiresAt,
}: {
  title: string;
  redeemedAt: number;
  status: string;
  expiresAt: number;
}) {
  const classes = useRewardStyles();

  return (
    <Paper className={classes.cardContainer}>
      <div className={classes.titleContainer}>
        {status === 'redeemed' ? (
          <div className={classes.fillRoot}>
            <SvgIcon
              component={CheckIcon}
              color="primary"
              className={classes.icon}
              viewBox={'0 0 30 30'}
            />
          </div>
        ) : (
          <div className={classes.expiredRoot}>
            <PriorityHighIcon className={classes.icon} fontSize="small" />
          </div>
        )}
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      </div>
      <Typography variant="body2" gutterBottom>
        {status === 'redeemed'
          ? `Redeemed on ${moment.unix(redeemedAt).format('llll')}`
          : `Expired on ${moment.unix(expiresAt).format('llll')}`}
      </Typography>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 400,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

type Props = {
  rewardCardList: RewardCardService.RewardCard[];
};

export default function RedeemedRewardCard({ rewardCardList }: Props) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.root}>
      {rewardCardList.map((rewardCard, idx) => (
        <RewardContainer
          key={idx}
          title={rewardCard.title}
          status={rewardCard.status}
          expiresAt={rewardCard.expiresAt}
          redeemedAt={rewardCard.redeemedAt}
        />
      ))}
    </Grid>
  );
}
