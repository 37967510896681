import React from 'react';
import moment from 'moment';
import { getIn, FieldProps } from 'formik';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import DatePicker, { Props as DatePickerProps } from '../DatePicker';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = FieldProps &
  Omit<DatePickerProps, 'error' | 'name' | 'onChange' | 'value'> & {
    initvalue: moment.Moment;
  };

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const fieldToDatePicker = ({
  form,
  field,
  ...props
}: Props): DatePickerProps => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return {
    ...props,
    ...field,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    InputProps: {
      onBlur: field.onBlur,
    },
    onChange: (date: MaterialUiPickersDate) => {
      form.setFieldValue(name, date ? date.endOf('day') : field.value);
    },
    disabled: isSubmitting || props.disabled,
    value: !field.value ? props.initvalue : field.value,
  };
};

/**
 * DatePickerAdapter wraps the DatePicker component returning a moment date at
 * the end of the day.
 */
const DatePickerAdapter: React.FC<Props> = (props) => (
  <DatePicker {...fieldToDatePicker(props)} />
);

export default DatePickerAdapter;
