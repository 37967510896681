import React, { PropsWithChildren } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiFormLabel from '@material-ui/core/FormLabel';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const Select = withStyles({
  root: {
    width: '100%',
  },
})(MuiSelect);

const FormLabel = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
    width: '100%',
  },
}))(MuiFormLabel);

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    marginTop: 11,
    marginBottom: theme.spacing(1),
  },
  inputContainer: ({ fullWidth }: { fullWidth?: boolean }) => ({
    flexGrow: fullWidth ? 1 : 0,
  }),
  grow: {
    flexGrow: 1,
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export type Props = PropsWithChildren<
  SelectProps & {
    error?: boolean;
    helperText?: string;
    label?: string;
    fullWidth?: boolean;
    alignRight?: boolean;
  }
>;

export default function Selector({
  alignRight = false,
  children,
  error = false,
  fullWidth = false,
  helperText,
  label,
  ...SelectProps
}: Props) {
  const classes = useStyles({ fullWidth });

  return (
    <FormControl error={error} fullWidth={fullWidth || alignRight}>
      <div className={classes.selectContainer}>
        <div className={classes.labelContainer}>
          <FormLabel>{label}</FormLabel>
        </div>

        <div className={classes.inputContainer}>
          <Select fullWidth={fullWidth || alignRight} {...SelectProps}>
            {children}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </div>
      </div>
    </FormControl>
  );
}
