import React, { useEffect, useState } from 'react';
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
  EmailShareButton,
  WhatsappIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';

import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as RewardService from '../../api/rewardService';

import Paper from '../Paper';

const useRewardStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 350,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(),
  },
  shareTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 150,
  },
  showDetailContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  showDetailButton: {
    color: theme.palette.text.secondary,
  },
  grow: {
    flexGrow: 1,
  },
  finePrint: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  markdown: {
    marginTop: 10,
    marginBottom: 10,
    fontFamily: 'ClarikaGeometric',
    fontSize: 14,
    fontWeight: 400,
  },
  shareContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
  },
  detail: {
    marginBottom: theme.spacing(),
  },
}));

export function RewardContainer({
  joiningReward,
  welcomeReward,
  referralReward,
  link,
  merchantName,
}: {
  joiningReward: RewardService.Reward | null;
  welcomeReward?: RewardService.Reward | null;
  referralReward: RewardService.Reward | null;
  link: string;
  merchantName: string;
}) {
  const classes = useRewardStyles();
  const [showDetails, setShowDetails] = useState(false);
  const facebookID = process.env.REACT_APP_FACEBOOK_ID;

  const handleReferralLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(`${link}`).then(
        () => {
          alert('Weblink is copied to the Clipboard');
        },
        () => {
          alert('Weblink is not copied to the Clipboard');
        }
      );
    }
  };

  return (
    <Paper className={classes.cardContainer}>
      <div className={classes.showDetailContainer}>
        <div className={classes.grow} />
        <Button
          variant="text"
          onClick={() => setShowDetails(!showDetails)}
          className={classes.showDetailButton}
        >
          {showDetails ? 'Hide' : 'Expand'}
        </Button>
      </div>
      {referralReward && (
        <>
          <Typography variant="h6" className={classes.title} gutterBottom>
            You Get = {referralReward.title}
          </Typography>
          <Typography variant="body2" className={classes.detail} gutterBottom>
            Whenever a friend collects their first loyalty stamp
          </Typography>
        </>
      )}
      {joiningReward && (
        <>
          <Typography variant="h6" className={classes.title} gutterBottom>
            Friends Get = {joiningReward.title}
          </Typography>
          <Typography variant="body2" className={classes.detail} gutterBottom>
            Given to each new friend upon joining
          </Typography>{' '}
        </>
      )}
      {welcomeReward && (
        <>
          <Typography variant="h6" className={classes.title} gutterBottom>
            Friends Get = {welcomeReward.title}
          </Typography>
          <Typography variant="body2" className={classes.detail} gutterBottom>
            Given to each new friend upon joining
          </Typography>
        </>
      )}
      {showDetails && (
        <div className={classes.finePrint}>
          <Typography variant="h6" className={classes.title} gutterBottom>
            How it Works
          </Typography>
          <Typography variant="body2" gutterBottom>
            Copy the referral link and paste it in your social media posts or
            messages to friends. This is a unique web link that lets us know you
            are the referrer whenever anyone new joins.
            <br /> <br /> When your friends click this link, they will be
            invited to join our loyalty program. You'll receive referral bonuses
            instantly whenever your friends join and earn their first stamp.
          </Typography>
        </div>
      )}
      <Typography variant="h6" className={classes.shareTitle} gutterBottom>
        Share This Link Now
      </Typography>
      <div className={classes.shareContainer}>
        {facebookID && (
          <FacebookMessengerShareButton url={link} appId={facebookID}>
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
        )}
        <WhatsappShareButton
          url={link}
          title={`${merchantName} Loyalty Program`}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <FacebookShareButton
          url={link}
          title={`${merchantName} Loyalty Program`}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton
          url={link}
          title={`${merchantName} Loyalty Program`}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <EmailShareButton
          url={link}
          subject={`${merchantName} Loyalty Program`}
          body={`I've joined the ${merchantName} rewards program on Flex Rewards and recommend it. You can join with this link.${
            joiningReward
              ? " You'll get a " + joiningReward.title + '.\n'
              : '\n'
          }`}
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
      <div className={classes.actionContainer}>
        <Button
          color="primary"
          className={classes.actionButton}
          onClick={handleReferralLink}
        >
          Copy Link
        </Button>
      </div>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 400,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

type Props = {
  merchantId: string;
  merchantName: string;
  joiningRewardId: string;
  referralRewardId: string;
  handleCopyLink: (rewardCardId: string) => void;
  link: string;
};

export default function ReferralCard({
  merchantId,
  merchantName,
  joiningRewardId,
  referralRewardId,
  handleCopyLink,
  link,
}: Props) {
  const classes = useStyles();
  const [joiningReward, setJoiningReward] =
    useState<RewardService.Reward | null>(null);
  const [referralReward, setReferralReward] =
    useState<RewardService.Reward | null>(null);
  const [welcomeReward, setWelcomeReward] =
    useState<RewardService.Reward | null>(null);

  useEffect(() => {
    const fetchJoiningReward = async () => {
      try {
        const res = await RewardService.get(joiningRewardId);

        setJoiningReward(res);
      } catch (error) {
        console.log(error);
      }
    };

    if (joiningRewardId) {
      fetchJoiningReward();
    }
  }, [joiningRewardId]);

  useEffect(() => {
    const fetchReferralReward = async () => {
      try {
        const res = await RewardService.get(referralRewardId);

        setReferralReward(res);
      } catch (e) {
        console.log(e);
      }
    };

    if (referralRewardId) {
      fetchReferralReward();
    }
  }, [referralRewardId]);

  useEffect(() => {
    const fetchWelcomeReward = async () => {
      try {
        const res = await RewardService.getWelcomeReward(merchantId);

        setWelcomeReward(res);
      } catch (e) {
        console.log(e);
      }
    };

    if (merchantId) {
      fetchWelcomeReward();
    }
  }, [merchantId]);

  return (
    <Grid item xs={12} className={classes.root}>
      <RewardContainer
        joiningReward={joiningReward}
        welcomeReward={welcomeReward}
        referralReward={referralReward}
        link={link}
        merchantName={merchantName}
      />
    </Grid>
  );
}
