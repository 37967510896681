import request from './request';
import deserialize from './deserialize';

export type LoyaltyReward = {
  id: string;
  createdAt: number;
  details: string;
  redemptionCost: number;
  status: string;
  updatedAt: number;
  merchant: {
    type: 'merchants';
    id: string;
  };
  reward: {
    type: 'rewards';
    id: string;
  };
};

export type RedemptionMilestone = {
  id: string;
  createdAt: number;
  threshold: number;
  updatedAt: number;
  validityPeriodDays: number;
  loyaltyCampaign: {
    type: 'loyalty_campaigns';
    id: string;
  };
  reward: {
    type: 'rewards';
    id: string;
  };
};

export function getByMerchantId(merchantId: string) {
  return request<LoyaltyReward>({
    url: `/api/v3/merchants/${merchantId}/loyalty_reward`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function get(loyaltyRewardId: string) {
  return request<LoyaltyReward>({
    url: `/api/v3/loyalty_rewards/${loyaltyRewardId}`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function listMilestone(loyaltyRewardId: string) {
  return request<RedemptionMilestone[]>({
    url: `/api/v3/loyalty_rewards/${loyaltyRewardId}/redemption_milestones`,
    method: 'GET',
    transformResponse: deserialize,
  });
}
