import request from './request';
import deserialize from './deserialize';

type Image = {
  id: string;
  versions: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
};

export type Reward = {
  id: string;
  createdAt: number;
  gift: boolean;
  info: string;
  sell: boolean;
  title: string;
  updatedAt: number;
  what: string;
  images?: Image[];
};

export function get(rewardId: string) {
  return request<Reward>({
    url: `/api/v3/rewards/${rewardId}`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function getWelcomeReward(merchantId: string) {
  return request<Reward>({
    url: `/api/v3/merchants/${merchantId}/welcome_reward`,
    method: 'GET',
    transformResponse: deserialize,
  });
}
