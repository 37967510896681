import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import SignIn from '../../screens/SignIn';
import SignUp from '../../screens/SignUp';
import Root from '../../screens/Root';
import Errors from '../../screens/Errors';
import MainSplash from '../../screens/MainSplash';
import ForgetPassword from '../../screens/ForgotPassword';
import ProfileScreen from '../../screens/Profile';
import MerchantScreen from '../../screens/Merchant';
import AboutScreen from '../../screens/About';
import RedeemContactlessScreen from '../../screens/RedeemContactless';
import JoinMerchantScreen from '../../screens/JoinMerchant';
import RedeemCouponScreen from '../../screens/RedeemCoupon';
import ReferralScreen from '../../screens/Referral';
import SingleUseStatusScreen from '../../screens/RedeemSingleUseStatus';

import { client } from '../../api';
import useAuthContext from '../../hooks/useAuthContext';
import AuthLayout from '../Layout/AuthLayout';

/**
 * APIErrorHandler sets up api error handlers which need access to the router.
 */
function APIErrorHandler({ children }: React.PropsWithChildren<{}>) {
  const auth = useAuthContext();
  const history = useHistory();
  const [isReady, setIsReady] = useState(false);

  // Handle 401 Unauthorized and 403 Forbidden errors
  useEffect(() => {
    const interceptor = client.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;

        switch (response.status) {
          case 401:
            // Logout the user
            auth?.removeSession();

            break;
          // Not sure if this needed
          // case 403:
          //   // Redirect to the root path
          //   history.replace('/');

          //   break;
        }

        return Promise.reject(error);
      }
    );

    setIsReady(true);

    return () => client.interceptors.response.eject(interceptor);
  }, [history, auth]);

  if (!isReady) {
    return null;
  }

  return <>{children}</>;
}

/**
 * AppRouter handles application level routing for the app.
 */
export default function AppRouter() {
  const auth = useAuthContext();

  // Don't render until the authentication context is ready
  if (!auth?.isReady) {
    return null;
  }

  return (
    <Router>
      <APIErrorHandler>
        <Switch>
          {/* Authentication Routes */}
          <PublicRoute path="/login">
            <SignIn />
          </PublicRoute>

          <PublicRoute path="/signup">
            <SignUp />
          </PublicRoute>

          <PublicRoute path="/forgot_password">
            <ForgetPassword />
          </PublicRoute>

          {/* App Routes */}
          {auth && !auth.isAuthenticated && (
            <PublicRoute exact path="/">
              <MainSplash />
            </PublicRoute>
          )}

          {auth && auth.isAuthenticated && (
            <PrivateRoute path="/">
              <AuthRoutes />
            </PrivateRoute>
          )}

          {/* Error Route */}
          <Route>
            <Errors.NotFound />
          </Route>
        </Switch>
      </APIErrorHandler>
    </Router>
  );
}

function AuthRoutes() {
  let { path } = useRouteMatch();

  return (
    <AuthLayout>
      <Switch>
        <PrivateRoute exact path={`${path}`}>
          <Root />
        </PrivateRoute>

        <PrivateRoute path={`${path}profile`}>
          <ProfileScreen />
        </PrivateRoute>
        <PrivateRoute path={`${path}about`}>
          <AboutScreen />
        </PrivateRoute>

        <PrivateRoute exact path={`${path}merchant/:merchantId`}>
          <MerchantScreen />
        </PrivateRoute>

        <PrivateRoute exact path={`${path}contactless/:contactlessCode`}>
          <RedeemContactlessScreen />
        </PrivateRoute>

        <PrivateRoute exact path={`${path}coupon/:couponCode`}>
          <RedeemCouponScreen />
        </PrivateRoute>

        <PrivateRoute exact path={`${path}join_merchant/:merchantId`}>
          <JoinMerchantScreen />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`${path}referral/merchantCode/:merchantCode/userCode/:userCode`}
        >
          <ReferralScreen />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`${path}merchant/:merchantId/stampNum/:stampNum/singleUseRedeem/status/:status/msg/:msg`}
        >
          <SingleUseStatusScreen />
        </PrivateRoute>
      </Switch>
    </AuthLayout>
  );
}
