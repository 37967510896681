import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import Sound from 'react-sound';
import moment from 'moment';
import confetti from '../../assets/animations/top-down-confetti.json';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { SvgIcon, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as CheckIcon } from '../../assets/icons/Check.svg';
import FullScreenDialog, { FullScreenDialogProps } from '../FullScreenDialog';

import * as ProfileService from '../../api/profileService';
import * as StampCampaignService from '../../api/stampCampaignService';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    overflow: 'hidden',
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  vip: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#ffae23',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fillRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.main,
  },
  timestamp: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  box: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  close: {
    marginLeft: 'auto',
  },
  closeIcon: {
    color: theme.palette.common.black,
  },
  moreStampContainer: {
    marginTop: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: '#fafafa',
    borderRadius: 10,
    cursor: 'pointer',
    maxWidth: 300,
  },
}));

type Props = Pick<FullScreenDialogProps, 'open' | 'onClose'> & {
  campaignId: string;
  quantity: number;
  isMultipleStamp: boolean;
  stampAdded: string;
  handleAddMoreStamp: () => void;
  allowAddMoreStamp: boolean;
};

export default function AddStampSuccessDialog({
  onClose,
  open,
  campaignId,
  quantity,
  isMultipleStamp,
  stampAdded,
  handleAddMoreStamp,
  allowAddMoreStamp,
}: Props) {
  const classes = useStyles();
  const [profile, setProfile] = useState<ProfileService.Profile | null>(null);
  const [stampCampaign, setStampCampaign] =
    useState<StampCampaignService.StampCampaign | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSoundPlaying, setIsSoundPlaying] = useState(false);

  useEffect(() => {
    let init = false;
    const fetchProfile = async () => {
      const res = await ProfileService.get();
      if (!init) {
        setProfile(res);
        setIsPlaying(true);
        setIsSoundPlaying(true);
      }
    };

    fetchProfile();
    return () => {
      init = true;
    };
  }, []);

  useEffect(() => {
    const fetchStampCampaign = async () => {
      const res = await StampCampaignService.get(campaignId);
      setStampCampaign(res);
    };
    fetchStampCampaign();
  }, [campaignId]);

  if (!profile) {
    return null;
  }

  if (!stampCampaign) {
    return null;
  }

  return (
    <FullScreenDialog open={open}>
      {isPlaying && (
        <Lottie
          animationData={confetti}
          loop={isPlaying}
          autoplay={false}
          style={{
            left: 0,
            right: 0,
            position: 'absolute',
          }}
        />
      )}
      {isSoundPlaying && (
        <Sound
          url={
            isMultipleStamp ? '../sound/double-ding.mp3' : '../sound/ding.mp3'
          }
          playStatus={isSoundPlaying ? 'PLAYING' : 'STOPPED'}
          onFinishedPlaying={() => {
            setIsSoundPlaying(false);
            setIsPlaying(false);
          }}
        />
      )}
      <div className={classes.box}>
        <div className={classes.close}>
          <IconButton
            onClick={() => {
              if (onClose) {
                onClose({}, 'backdropClick');
              }
            }}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </div>
      <div className={classes.cardContainer}>
        <Typography variant="h4" className={classes.title} gutterBottom>
          {profile.firstName} {profile.lastName}
        </Typography>
        <div className={classes.fillRoot}>
          <SvgIcon
            component={CheckIcon}
            color="primary"
            className={classes.icon}
            viewBox={'0 0 30 30'}
            fontSize="large"
          />
        </div>
        {stampCampaign.type === 'prize' &&
        quantity === stampCampaign.maxStamps ? (
          <>
            <Typography
              variant="body1"
              className={classes.timestamp}
              gutterBottom
            >
              {stampCampaign.notification}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" className={classes.title} gutterBottom>
              {stampAdded} Stamp{stampAdded === '1' ? '' : 's'} Collected
            </Typography>
            <Typography
              variant="body1"
              className={classes.timestamp}
              gutterBottom
            >
              {moment().format('llll')}
            </Typography>
            {allowAddMoreStamp && (
              <div
                className={classes.moreStampContainer}
                onClick={handleAddMoreStamp}
              >
                <Typography
                  variant="body1"
                  className={classes.timestamp}
                  gutterBottom
                >
                  {!isMultipleStamp
                    ? 'To collect more than 1 stamp, please pass your device to a staff member'
                    : 'To collect more stamps, please pass your device to a staff member'}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
    </FullScreenDialog>
  );
}
