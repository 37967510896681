import React from 'react';
import { getIn, FieldProps } from 'formik';

import Selector, { Props as SelectorProps } from '../Selector';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = FieldProps & Omit<SelectorProps, 'name' | 'value'>;

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

function fieldToSelector({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  ...props
}: Props): SelectorProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    disabled: disabled ?? isSubmitting,
    error: showError,
    helperText: showError ? fieldError : helperText,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}

/**
 * ImageUploader uploads the image selected with and ImageSelect and it for
 * Formik.
 */
export default function SelectorAdapter(props: Props) {
  return <Selector {...fieldToSelector(props)} />;
}
