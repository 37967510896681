import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import AddToHomescreen from 'react-add-to-homescreen';

import * as StampCardService from '../../api/stampCardService';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import LoyaltyCard from '../../components/Card/LoyaltyCard';

import useDeepLinkContext from '../../hooks/useDeepLinkContext';
import useDeepLinkHandler from '../../hooks/useDeepLinkHandler';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  noStamp: {
    marginTop: theme.spacing(10),
  },
}));

/**
 * Root redirects to the user's companies dashboard.
 */
export default function Root() {
  const classes = useStyles();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const feature = params['feature'];
  const merchantId = params['merchant'];
  const contactlessCode = params['code'];
  const couponCode = params['code'];
  const merchantCode = params['merchant'];
  const userCode = params['user'];
  const singleUseCode = params['code'];
  const store = params['store'];
  const stampNum = params['numOfStamps'];
  const [stampCardList, setStampCardList] =
    useState<StampCardService.StampCardList>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dl = useDeepLinkContext();
  const initialized = useRef(false);

  useEffect(() => {
    const setDeeplink = () => {
      if (dl && isLoading && !initialized.current) {
        initialized.current = true;
        dl.setDeeplink({
          feature: feature && typeof feature == 'string' ? feature : undefined,
          merchantId:
            merchantId && typeof merchantId == 'string'
              ? merchantId
              : undefined,
          contactlessCode:
            contactlessCode && typeof contactlessCode == 'string'
              ? contactlessCode
              : undefined,
          couponCode:
            couponCode && typeof couponCode == 'string'
              ? couponCode
              : undefined,
          merchantCode:
            merchantCode && typeof merchantCode == 'string'
              ? merchantCode
              : undefined,
          userCode:
            userCode && typeof userCode == 'string' ? userCode : undefined,
          singleUseCode:
            singleUseCode && typeof singleUseCode == 'string'
              ? singleUseCode
              : undefined,
          store: store && typeof store == 'string' ? store : undefined,
          stampNum:
            stampNum && typeof stampNum == 'string' ? stampNum : undefined,
        });
      }
      setIsLoading(false);
    };

    if (isLoading) {
      setDeeplink();
    }
  }, [
    isLoading,
    feature,
    merchantId,
    contactlessCode,
    couponCode,
    merchantCode,
    userCode,
    dl,
    singleUseCode,
    store,
    stampNum,
  ]);

  useEffect(() => {
    const fetch = async () => {
      const res = await StampCardService.list();

      setStampCardList(res);
      setIsLoading(false);
    };

    fetch();
  }, []);

  useDeepLinkHandler();

  const handleAddToHomescreenClick = () => {
    alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className={classes.grow} />
      <div className={classes.container}>
        {stampCardList.length > 0 ? (
          stampCardList.map((stampcard, idx) => {
            return (
              <LoyaltyCard
                key={idx}
                merchantId={stampcard.merchant.id}
                to={`/merchant/${stampcard.merchant.id}`}
                loyaltyCampaignId={stampcard.loyalty_campaign.id}
              />
            );
          })
        ) : (
          <Typography
            variant="h5"
            color="textSecondary"
            className={classes.noStamp}
          >
            No loyalty cards yet
          </Typography>
        )}
      </div>
      <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />
    </>
  );
}
