import request from './request';
import deserialize from './deserialize';

export type StampCampaign = {
  id: string;
  createdAt: number;
  description: string;
  details: string;
  maxStamps: number;
  multipleCards: boolean;
  notification: string;
  status: string;
  type: string;
  updatedAt: number;
};

export function get(campaignId: string) {
  return request<StampCampaign>({
    url: `/api/v3/stamp_campaigns/${campaignId}`,
    method: 'GET',
    transformResponse: deserialize,
  });
}
