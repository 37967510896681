import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
// import { useHistory } from 'react-router-dom';
import moment from 'moment';
// import { useGoogleLogout } from 'react-google-login';
// import { FacebookLoginClient } from '@greatsumini/react-facebook-login';

// import * as AuthService from '../../api/authService';
import * as ProfileService from '../../api/profileService';
// import * as UserService from '../../api/userService';

// import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Paper from '../../components/Paper';
import Profile from '../../assets/images/profile.png';
import EditNameDialog from '../../components/Dialogs/EditNameDialog';
import EditGenderDialog from '../../components/Dialogs/EditGenderDialog';
import EditDOBDialog from '../../components/Dialogs/EditDOBDialog';
import EditEmailDialog from '../../components/Dialogs/EditEmailDialog';
import EditAltEmailDialog from '../../components/Dialogs/EditAltEmailDialog';
import EditMobileDialog from '../../components/Dialogs/EditMobileDialog';
import EditCompanyDialog from '../../components/Dialogs/EditCompanyDialog';
import UpdatePasswordDialog from '../../components/Dialogs/UpdatePasswordDialog';
// import DeleteAccountDialog from '../../components/Dialogs/DeleteAccountDialog';
import Spinner from '../../components/Spinner';

// import useAuthContext from '../../hooks/useAuthContext';
// import useNavContext from '../../hooks/useNavContext';
// import useDeepLinkContext from '../../hooks/useDeepLinkContext';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: 350,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 12,
    textAlign: 'left',
  },
  logoContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 80,
    height: 80,
  },
  value: {
    color: '#757575',
  },
  email: {
    color: '#757575',
    paddingRight: 12,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'right',
    width: 300,
    marginBottom: theme.spacing(),
  },
  editIcon: {
    color: '#CCCCCC',
    height: 18,
    weight: 18,
  },
}));

export default function ProfileScreen() {
  // const deeplink = useDeepLinkContext();
  // const auth = useAuthContext();
  // const nav = useNavContext();
  // const history = useHistory();
  const classes = useStyles();
  const [profile, setProfile] = useState<ProfileService.Profile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showEditNameDialog, setShowEditNameDialog] = useState(false);
  const [showEditGenderDialog, setShowEditGenderDialog] = useState(false);
  const [showEditDOBDialog, setShowEditDOBDialog] = useState(false);
  const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] =
    useState(false);
  const [showEditEmailDialog, setShowEditEmailDialog] = useState(false);
  const [showEditMobileDialog, setShowEditMobileDialog] = useState(false);
  const [showEditCompanyDialog, setShowEditCompanyDialog] = useState(false);
  const [showEditAltEmailDialog, setShowEditAltEmailDialog] = useState(false);
  // const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);
  // const googleWebClient = process.env
  //   .REACT_APP_GOOGLE_OAUTH_WEB_CLIENT as string;
  // const facebookID = process.env.REACT_APP_FACEBOOK_ID as string;

  // const { signOut } = useGoogleLogout({
  //   clientId: googleWebClient,
  //   onLogoutSuccess: () => {
  //     console.log('user logout');
  //   },
  // });

  // const handleLogOut = async () => {
  //   FacebookLoginClient.init(() => {}, {
  //     appId: facebookID,
  //   });
  //   if (auth && nav) {
  //     auth.removeSession();
  //     nav.removeNav();
  //     history.replace({});
  //     history.push(`/`);
  //   }
  //   if (deeplink) {
  //     deeplink.removeDeeplink();
  //   }
  //   signOut();
  //   const facebookWin = FacebookLoginClient.getFB();
  //   if (facebookWin) {
  //     FacebookLoginClient.logout(() => {
  //       console.log('FB logout completed!');
  //     });
  //   }
  // };

  useEffect(() => {
    const fetch = async () => {
      const res = await ProfileService.get();

      if (res) {
        setProfile(res);
      }
      setIsLoading(false);
    };

    fetch();
  }, []);

  const refreshProfile = async () => {
    setIsLoading(true);
    const res = await ProfileService.get();

    if (res) {
      setProfile(res);

      setIsLoading(false);
    }
  };

  if (!profile) {
    return null;
  }

  if (isLoading) {
    return <Spinner />;
  } else {
    return (
      <>
        <div className={classes.grow} />
        <div className={classes.container}>
          <Grid item xs={12} className={classes.root}>
            <Paper>
              <div className={classes.cardContainer}>
                <div className={classes.logoContainer}>
                  <img src={Profile} className={classes.logo} alt="profile" />
                </div>
                <div className={classes.infoContainer}>
                  <Typography
                    variant="body1"
                    className={classes.title}
                    gutterBottom
                  >
                    Name:
                  </Typography>
                  <div className={classes.grow} />
                  <Typography
                    variant="body1"
                    className={classes.value}
                    gutterBottom
                  >
                    {profile.firstName} {profile.lastName}
                  </Typography>
                  <IconButton onClick={() => setShowEditNameDialog(true)}>
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                </div>
                <div className={classes.infoContainer}>
                  <Typography
                    variant="body1"
                    className={classes.title}
                    gutterBottom
                  >
                    Email:
                  </Typography>
                  <div className={classes.grow} />
                  <Typography
                    variant="body1"
                    className={classes.email}
                    gutterBottom
                  >
                    {profile.email}
                  </Typography>
                  <IconButton onClick={() => setShowEditEmailDialog(true)}>
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                </div>
                <div className={classes.infoContainer}>
                  <Typography
                    variant="body1"
                    className={classes.title}
                    gutterBottom
                  >
                    Alternate Email:
                  </Typography>
                  <div className={classes.grow} />
                  <Typography
                    variant="body1"
                    className={classes.email}
                    gutterBottom
                  >
                    {profile.alt_email ? profile.alt_email : ' '}
                  </Typography>
                  <IconButton onClick={() => setShowEditAltEmailDialog(true)}>
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                </div>
                <div className={classes.infoContainer}>
                  <Typography
                    variant="body1"
                    className={classes.title}
                    gutterBottom
                  >
                    Mobile:
                  </Typography>
                  <div className={classes.grow} />
                  <Typography
                    variant="body1"
                    className={classes.email}
                    gutterBottom
                  >
                    {profile.mobNum ? profile.mobNum : ' '}
                  </Typography>
                  {((!profile.mobNum && profile.email) || profile.mobNum) && (
                    <IconButton onClick={() => setShowEditMobileDialog(true)}>
                      <EditIcon className={classes.editIcon} />
                    </IconButton>
                  )}
                </div>
                <div className={classes.infoContainer}>
                  <Typography
                    variant="body1"
                    className={classes.title}
                    gutterBottom
                  >
                    Birthday:
                  </Typography>
                  <div className={classes.grow} />
                  <Typography
                    variant="body1"
                    className={classes.value}
                    gutterBottom
                  >
                    {profile.dob && profile.dob !== -62135596800
                      ? moment.unix(profile.dob).format('DD/MM/YYYY')
                      : ' '}
                  </Typography>
                  <IconButton onClick={() => setShowEditDOBDialog(true)}>
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                </div>
                <div className={classes.infoContainer}>
                  <Typography
                    variant="body1"
                    className={classes.title}
                    gutterBottom
                  >
                    Company:
                  </Typography>
                  <div className={classes.grow} />
                  <Typography
                    variant="body1"
                    className={classes.value}
                    gutterBottom
                  >
                    {profile.company ? profile.company : ''}
                  </Typography>
                  <IconButton onClick={() => setShowEditCompanyDialog(true)}>
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                </div>
                <div className={classes.infoContainer}>
                  <Typography
                    variant="body1"
                    className={classes.title}
                    gutterBottom
                  >
                    Gender:
                  </Typography>
                  <div className={classes.grow} />
                  <Typography
                    variant="body1"
                    className={classes.value}
                    gutterBottom
                  >
                    {!profile.gender
                      ? 'Not specified'
                      : capitalize(profile.gender)}
                  </Typography>
                  <IconButton onClick={() => setShowEditGenderDialog(true)}>
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                </div>
                <div className={classes.infoContainer}>
                  <Typography
                    variant="body1"
                    className={classes.title}
                    gutterBottom
                  >
                    Password:
                  </Typography>
                  <div className={classes.grow} />
                  <Typography
                    variant="body1"
                    className={classes.value}
                    gutterBottom
                  >
                    *********
                  </Typography>
                  <IconButton onClick={() => setShowUpdatePasswordDialog(true)}>
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                </div>
                {/* <Button
                  variant="text"
                  color="secondary"
                  onClick={() => setShowDeleteAccountDialog(true)}
                >
                  Delete Account
                </Button> */}
              </div>
            </Paper>
          </Grid>
        </div>
        <EditNameDialog
          open={showEditNameDialog}
          onClose={() => setShowEditNameDialog(false)}
          profile={profile}
          confirmUpdate={(updateStatus: boolean) => {
            if (updateStatus) {
              refreshProfile();
            }
            setShowEditNameDialog(false);
          }}
        />
        <EditGenderDialog
          open={showEditGenderDialog}
          onClose={() => setShowEditGenderDialog(false)}
          profile={profile}
          confirmUpdate={(updateStatus: boolean) => {
            if (updateStatus) {
              refreshProfile();
            }
            setShowEditGenderDialog(false);
          }}
        />
        <EditDOBDialog
          open={showEditDOBDialog}
          onClose={() => setShowEditDOBDialog(false)}
          profile={profile}
          confirmUpdate={(updateStatus: boolean) => {
            if (updateStatus) {
              refreshProfile();
            }
            setShowEditDOBDialog(false);
          }}
        />
        <EditEmailDialog
          open={showEditEmailDialog}
          onClose={() => setShowEditEmailDialog(false)}
          profile={profile}
          confirmUpdate={(updateStatus: boolean) => {
            if (updateStatus) {
              refreshProfile();
            }
            setShowEditEmailDialog(false);
          }}
        />
        <EditAltEmailDialog
          open={showEditAltEmailDialog}
          onClose={() => setShowEditAltEmailDialog(false)}
          profile={profile}
          confirmUpdate={(updateStatus: boolean) => {
            if (updateStatus) {
              refreshProfile();
            }
            setShowEditAltEmailDialog(false);
          }}
        />
        <EditMobileDialog
          open={showEditMobileDialog}
          onClose={() => setShowEditMobileDialog(false)}
          profile={profile}
          confirmUpdate={(updateStatus: boolean) => {
            if (updateStatus) {
              refreshProfile();
            }
            setShowEditMobileDialog(false);
          }}
        />
        <EditCompanyDialog
          open={showEditCompanyDialog}
          onClose={() => setShowEditCompanyDialog(false)}
          profile={profile}
          confirmUpdate={(updateStatus: boolean) => {
            if (updateStatus) {
              refreshProfile();
            }
            setShowEditCompanyDialog(false);
          }}
        />
        <UpdatePasswordDialog
          open={showUpdatePasswordDialog}
          onClose={() => setShowUpdatePasswordDialog(false)}
          confirmUpdate={() => {
            setShowUpdatePasswordDialog(false);
          }}
        />
        {/* <DeleteAccountDialog
          userId={profile.id}
          open={showDeleteAccountDialog}
          email={profile.email}
          onClose={() => setShowDeleteAccountDialog(false)}
          confirmUpdate={async () => {
            setShowDeleteAccountDialog(false);

            try {
              await UserService.archive(profile.id);

              handleLogOut();
            } catch (e) {
              alert('Account not deleted.');
            }
          }}
        /> */}
      </>
    );
  }
}
