import request from './request';
import deserialize from './deserialize';

export type Membership = {
  id: string;
  createdAt: number;
  totalStamps: number;
  updateAt: number;
  merchant: {
    type: 'merchants';
    id: string;
  };
  loyaltyProgram: {
    type: 'loyalty_programs';
    id: string;
  };
  user: {
    type: 'users';
    id: string;
  };
};

export function get(merchantId: string) {
  return request<Membership>({
    url: `/api/v3/merchants/${merchantId}/membership`,
    method: 'GET',
    transformResponse: deserialize,
  });
}
