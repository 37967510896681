import React from 'react';
import { FieldProps } from 'formik';

import SelectStampGroup, {
  SelectStampGroupProps,
} from '../Card/SelectStampGroup';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = FieldProps & Omit<SelectStampGroupProps, 'onChange'>;

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

function fieldToStampGroupSelector({
  form,
  field,
  ...props
}: Props): SelectStampGroupProps {
  const { name } = field;

  return {
    ...props,
    onChange: (value: string) => {
      form.setFieldValue(name, value);
    },
    value: field.value,
  };
}

/**
 * StampGroupSelector select no. of stamp selected for
 * Formik.
 */
export default function StampGroupSelectorAdapter(props: Props) {
  return <SelectStampGroup {...fieldToStampGroupSelector(props)} />;
}
