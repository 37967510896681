import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import * as StampCardService from '../../api/stampCardService';

import Spinner from '../../components/Spinner';
import AddStampDialog from '../../components/Dialogs/AddStampDialog';
import AddStampSingleUseSuccessDialog from '../../components/Dialogs/AddStampSingleUseSuccessDialog';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';

interface RouteParams {
  stampNum: string;
  merchantId: string;
  status: string;
  msg: string;
}

/**
 * Merchant
 */
export default function RedeemSingleUseStatusScreen() {
  const { stampNum, merchantId, status, msg } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [addedStamp, setAddedStamp] = useState(0);
  const [launchAddStampDialog, setLaunchAddStampDialog] = useState(false);
  const [stampSuccessDialog, setStampSuccessDialog] = useState(false);
  const [stampCard, setStampCard] =
    useState<StampCardService.StampCard | null>();
  const [launchAlertDialog, setLaunchAlertDialog] = useState(false);
  const errorMsg = msg === 'undefined' ? 'Qr is invalid' : msg;

  const history = useHistory();

  const fetchStampCard = useCallback(async () => {
    try {
      const res = await StampCardService.getByMerchantId(merchantId);
      setStampCard(res);
      setIsLoading(false);
      setStampSuccessDialog(true);
    } catch (e) {
      console.log('Error: Fetch Stampcard from merchant');
    }
  }, [merchantId]);

  useEffect(() => {
    let init = false;
    if (status === 'success') {
      if (!init && isLoading && merchantId) {
        fetchStampCard();
      }
    } else {
      if (!init && status === 'fail') {
        setIsLoading(false);
        setLaunchAlertDialog(true);
      }
    }

    return () => {
      init = true;
    };
  }, [merchantId, isLoading, fetchStampCard, status]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 200,
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 200,
      }}
    >
      {launchAlertDialog && merchantId && (
        <ConfirmationDialog
          open={launchAlertDialog}
          onClose={() => {
            setLaunchAlertDialog(false);
            history.replace(`/merchant/${merchantId}`);
            window.location.reload();
          }}
          onCancel={() => {
            setLaunchAlertDialog(false);
            history.replace(`/merchant/${merchantId}`);
            window.location.reload();
          }}
          onConfirm={() => {
            setLaunchAlertDialog(false);
            history.replace(`/merchant/${merchantId}`);
            window.location.reload();
          }}
          title="Error"
          body={errorMsg}
          confirmButtonText="Dismiss"
        />
      )}
      {stampCard && merchantId && (
        <>
          <AddStampDialog
            stampCardId={stampCard.id}
            open={launchAddStampDialog}
            onClose={() => {
              setLaunchAddStampDialog(false);
              history.replace(`/merchant/${merchantId}`);
              window.location.reload();
            }}
            confirmRedeem={(addedStamp: number) => {
              setAddedStamp(addedStamp);
              setLaunchAddStampDialog(false);
              setStampSuccessDialog(true);
            }}
          />
          <AddStampSingleUseSuccessDialog
            open={stampSuccessDialog}
            onClose={() => {
              setStampSuccessDialog(false);
              history.replace(`/merchant/${merchantId}`);
              window.location.reload();
            }}
            campaignId={stampCard.loyalty_campaign.id}
            quantity={stampCard.quantity}
            isMultipleStamp={
              addedStamp === 0 ? parseInt(stampNum) > 1 : addedStamp > 1
            }
            stampAdded={addedStamp === 0 ? stampNum : addedStamp.toString()}
            handleAddMoreStamp={() => {
              setStampSuccessDialog(false);
              setLaunchAddStampDialog(true);
            }}
          />
        </>
      )}
    </div>
  );
}
