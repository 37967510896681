import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

import { Button } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as RewardCardService from '../../api/rewardCardService';
import * as RewardService from '../../api/rewardService';

import Paper from '../Paper';

const useRewardStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 350,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    padding: '0 16px',
  },
  info: {
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(2),
    padding: '0 16px',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  showDetailContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  showDetailButton: {
    color: theme.palette.text.secondary,
  },
  grow: {
    flexGrow: 1,
  },
  finePrint: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  markdown: {
    marginTop: 8,
    marginBottom: 8,
    fontFamily: 'ClarikaGeometric',
    fontSize: 14,
    fontWeight: 400,
    padding: '0 16px',
  },
  cardMedia: {
    height: 220,
    width: 350,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    backgroundSize: 'cover',
  },
}));

export function RewardContainer({
  title,
  rewardId,
  handleRedeemReward,
  expiredAt,
}: {
  title: string;
  rewardId: string;
  handleRedeemReward: () => void;
  expiredAt: number;
}) {
  const classes = useRewardStyles();
  const [reward, setReward] = useState<RewardService.Reward | null>(null);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const fetchReward = async () => {
      const res = await RewardService.get(rewardId);
      setReward(res);
    };

    fetchReward();
  }, [rewardId]);

  if (!reward) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.cardContainer}>
        <CardMedia
          className={classes.cardMedia}
          image={
            reward && reward.images && reward.images[0]
              ? reward.images[0].versions.xl
              : ''
          }
        />
        <div className={classes.showDetailContainer}>
          <div className={classes.grow} />
          <Button
            variant="text"
            onClick={() => setShowDetails(!showDetails)}
            className={classes.showDetailButton}
          >
            {showDetails ? 'Hide' : 'Expand'}
          </Button>
        </div>
        <Typography variant="h6" className={classes.title} gutterBottom>
          {title}
        </Typography>
        <div>
          <ReactMarkdown className={classes.markdown}>
            {reward.what}
          </ReactMarkdown>
        </div>
        {!showDetails && (
          <>
            <Typography variant="body2" className={classes.title} gutterBottom>
              Valid Until
            </Typography>
            <div>
              <Typography variant="body2" className={classes.info} gutterBottom>
                {moment.unix(expiredAt).format('MMM DD, YYYY')}
              </Typography>
            </div>
          </>
        )}
        {showDetails && (
          <div className={classes.finePrint}>
            <Typography variant="body2" className={classes.title} gutterBottom>
              The Fine Print
            </Typography>
            <div>
              <ReactMarkdown className={classes.markdown}>
                {reward.info}
              </ReactMarkdown>
            </div>
            <Typography variant="body2" className={classes.title} gutterBottom>
              Valid Until
            </Typography>
            <div>
              <Typography variant="body2" className={classes.info} gutterBottom>
                {moment.unix(expiredAt).format('MMM DD, YYYY')}
              </Typography>
            </div>
          </div>
        )}
        <div className={classes.actionContainer}>
          <Button
            color="primary"
            className={classes.actionButton}
            onClick={handleRedeemReward}
          >
            Redeem
          </Button>
        </div>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

type Props = {
  rewardCardList: RewardCardService.RewardCard[];
  handleRedeemReward: (rewardCardId: string) => void;
};

export default function RewardCard({
  rewardCardList,
  handleRedeemReward,
}: Props) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.root}>
      {rewardCardList.map((rewardCard, idx) => (
        <RewardContainer
          key={idx}
          title={rewardCard.title}
          rewardId={rewardCard.reward.id}
          handleRedeemReward={() => {
            handleRedeemReward(rewardCard.id);
          }}
          expiredAt={rewardCard.expiresAt}
        />
      ))}
    </Grid>
  );
}
