import React from 'react';
import ReactMarkdown from 'react-markdown';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '../Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 350,
  },
  markdown: {
    marginTop: 10,
    marginBottom: 10,
    fontFamily: 'ClarikaGeometric',
    fontSize: 14,
    fontWeight: 400,
  },
}));

type Props = {
  about: string;
  link: string;
};

export default function AboutCard({ about, link }: Props) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.root}>
      <Paper className={classes.cardContainer}>
        <div>
          <ReactMarkdown className={classes.markdown}>{about}</ReactMarkdown>
        </div>
        <Link href={link} target="blank">
          Visit Website
        </Link>
      </Paper>
    </Grid>
  );
}
