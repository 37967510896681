import axios from 'axios';

/**
 * Create an Axios Client with defaults
 */
export const client = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
});

let authTokenInterceptor: any = null;

/**
 * Add the authentication token into requests
 */
export function addAuthenticationInterceptor(token: string) {
  if (!authTokenInterceptor)
    authTokenInterceptor = client.interceptors.request.use(function (config) {
      config.headers['X-SESSION-TOKEN'] = token;

      return config;
    });
}

export function removeAuthenticationInterceptor() {
  client.interceptors.request.eject(authTokenInterceptor);
  authTokenInterceptor = null;
}
