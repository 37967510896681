import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as MapMarker } from '../../../assets/icons/MapMarkerExplore.svg';
import { ReactComponent as MapMarkerAlt } from '../../../assets/icons/MapMarkerExploreAlt.svg';

//------------------------------------------------------------------------------
//
//------------------------------------------------------------------------------

type Props = {
  lat: number;
  lng: number;
  selected?: boolean;
  handleStoreMarkerPress?: () => void;
};

export function PointMarker({
  lat,
  lng,
  selected,
  handleStoreMarkerPress,
}: Props) {
  return (
    <IconButton
      onClick={() => (handleStoreMarkerPress ? handleStoreMarkerPress() : null)}
    >
      <SvgIcon
        component={selected ? MapMarker : MapMarkerAlt}
        viewBox="0 0 28 32"
      />
    </IconButton>
  );
}
