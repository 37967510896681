import React, { useEffect, useState } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { RadioGroup } from 'formik-material-ui';

import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DialogPaper from '../DialogPaper';

import * as StoreService from '../../api/storeService';

export interface Values {
  storePINId: string;
}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    width: 150,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  subtext: {
    color: '#6E6E6E',
    textAlign: 'center',
    padding: theme.spacing(),
  },
  avatar: {
    height: 80,
    width: 80,
    boxShadow: '0 2px 10px rgba(199, 199, 199, 0.5)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
}));

type Props = Pick<DialogProps, 'open' | 'onClose'> & {
  merchantId: string;
  merchantLogo?: string;
  confirmStore: (storeId: string) => void;
};

export default function SelectMerchantStoreDialog({
  merchantId,
  merchantLogo,
  onClose,
  open,
  confirmStore,
}: Props) {
  const classes = useStyles();
  const [stores, setStores] = useState<StoreService.Store[]>([]);
  const ValidationSchema = Yup.object().shape({
    storePINId: Yup.string().required('Required'),
  });

  const initialValues: Values = {
    storePINId: '',
  };

  useEffect(() => {
    const fetchStores = async () => {
      const res = await StoreService.list(merchantId);
      setStores(res);
    };

    fetchStores();
  }, [merchantId]);

  const handleSubmit = async (values: Values, _: FormikHelpers<Values>) => {
    if (values.storePINId) {
      confirmStore(values.storePINId);
    }
  };

  if (stores.length === 0) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} PaperComponent={DialogPaper}>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={false}
      >
        {({ isSubmitting, values: { storePINId } }) => {
          return (
            <Form noValidate>
              <div className={classes.cardContainer}>
                <Avatar
                  alt="merchantLogo"
                  src={merchantLogo}
                  className={classes.avatar}
                />
                <Typography variant="h5" className={classes.title} gutterBottom>
                  Select Location
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.subtext}
                  gutterBottom
                >
                  WHERE ARE YOU NOW ?
                </Typography>
                <FormControl component="fieldset">
                  <Field component={RadioGroup} type="radio" name="storePINId">
                    {stores.map((store, idx) => (
                      <div
                        key={idx}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: 12,
                        }}
                      >
                        <FormControlLabel
                          value={store.pin}
                          control={<Radio color="primary" />}
                          label=""
                        />
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <Typography variant="body2" className={classes.title}>
                            {store.name}
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary">
                            {store.address}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </Field>
                </FormControl>
                <Button
                  type="submit"
                  color="primary"
                  className={classes.buttonContainer}
                  disabled={isSubmitting}
                >
                  CONTINUE
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
