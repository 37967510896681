import React, { useState, useEffect } from 'react';
import codes from 'country-calling-code';
import { Form, Formik, FormikHelpers, Field } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import { find } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import * as authService from '../../api/authService';
import useAuthContext from '../../hooks/useAuthContext';
import useNavContext from '../../hooks/useNavContext';
import useDeepLinkContext from '../../hooks/useDeepLinkContext';
import Flexlogo from '../../assets/images/logo-red.png';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import Spinner from '../../components/Spinner';

declare const window: any;

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      width: 400,
      margin: '0 auto',
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      margin: '0 auto',
      marginTop: theme.spacing(5),
    },
  },
  form: {
    marginTop: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(),
    justifyContent: 'center',
  },
  forgetContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  logoContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 140,
    height: 50,
  },
  merchantLogo: {
    height: 120,
    width: 120,
    borderRadius: '50%',
    objectFit: 'cover',
    boxShadow: '0 2px 10px rgba(199, 199, 199, 0.5)',
  },
  noAccountContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  orLabel: {
    marginTop: 12,
  },
  selectedTabLiner: {
    backgroundColor: theme.palette.primary.main,
    height: 3,
    width: '80%',
  },
  tabLiner: {
    backgroundColor: theme.palette.common.white,
    height: 3,
    width: '80%',
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  pinHeader: {
    textAlign: 'center',
  },
  actionsMobNum: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(),
    justifyContent: 'flex-end',
  },
}));

interface EmailValues {
  email: string;
  password: string;
}

interface MobileValues {
  countryCode: string;
  mobNum: string;
}

interface PINValues {
  PIN: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const ValidationMobileSchema = Yup.object().shape({
  countryCode: Yup.string().required('Required'),
  mobNum: Yup.string().required('Required'),
});

const ValidationEmailSchema = Yup.object().shape({
  email: Yup.string().required('Required').email('Not a valid email'),
  password: Yup.string()
    .required('Required')
    .min(8, 'Must be greater than 8 characters'),
});

const ValidationPINSchema = Yup.object().shape({
  PIN: Yup.string()
    .required('Required')
    .length(6, 'Required for length for Code is 6'),
});

const initialEmailValues: EmailValues = {
  email: '',
  password: '',
};

const initialMobileValues: MobileValues = {
  countryCode: 'US',
  mobNum: '',
};

const initialPINValues: PINValues = {
  PIN: '',
};

/**
 * SignIn displays the sign in page.
 */
export default function SignIn() {
  const classes = useStyles();
  const authentication = useAuthContext();
  const nav = useNavContext();
  const dl = useDeepLinkContext();
  const merchantLogo = dl?.deeplink?.logo;
  const [tabValue, setTabValue] = useState(0);
  const [verifyingPIN, setVerifyingPIN] = useState(false);
  const [selectedMobNum, setSelectedMobNum] = useState('');
  const [mobileToken, setMobileToken] = useState('');
  const [isMobileLoginProcessing, setIsMobileLoginProcessing] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [isMobileLoginError, setIsMobileLoginError] = useState(false);

  useEffect(() => {
    const handleMobileToken = async () => {
      if (mobileToken && selectedMobNum && authentication && nav) {
        try {
          const resAuth = await authService.mobileLogin(
            selectedMobNum,
            mobileToken
          );

          if (resAuth) {
            setSelectedMobNum('');
            setMobileToken('');
            setIsMobileLoginProcessing(false);
            authentication.setSession({
              id: resAuth.id,
              token: resAuth.token,
              userId: '1',
            });

            nav.setNav({
              menuNav: 'home',
            });
          }
        } catch (e) {
          console.log('error: ', e);
          setAlertText('Mobile Login Error');
          setAlertOpen(true);
        }
      }
    };

    if (
      mobileToken.length > 0 &&
      selectedMobNum.length > 0 &&
      isMobileLoginProcessing
    ) {
      handleMobileToken();
    }
  }, [
    isMobileLoginProcessing,
    nav,
    authentication,
    mobileToken,
    selectedMobNum,
  ]);

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleEmailSubmit = async (
    values: EmailValues,
    { setFieldError, setSubmitting }: FormikHelpers<EmailValues>
  ) => {
    const { email, password } = values;

    try {
      const res = await authService.signIn(email.toLowerCase(), password);

      authentication?.setSession({
        id: res.id,
        token: res.token,
        userId: '1',
      });
      nav?.setNav({
        menuNav: 'home',
      });
    } catch (e: any) {
      e.data.forEach((err: any) => {
        setFieldError(err.meta.attribute, err.detail);
      });

      setSubmitting(false);
    }
  };

  const generateRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response: any) => {
            console.log('Response Captcha: ', response);
          },
        },
        auth
      );
    }
  };

  const handleMobileSubmit = async (
    values: MobileValues,
    { setFieldError, setSubmitting }: FormikHelpers<MobileValues>
  ) => {
    const { mobNum, countryCode } = values;
    const callingCode = find(codes, function (code) {
      return code.isoCode2 === countryCode;
    });

    if (callingCode) {
      generateRecaptcha();
      let verifier = window.recaptchaVerifier;
      const phoneNum = '+' + callingCode.countryCodes + ' ' + mobNum.trim();
      setSelectedMobNum(phoneNum);

      signInWithPhoneNumber(auth, phoneNum, verifier)
        .then((result) => {
          window.confirmationResult = result;
          setVerifyingPIN(true);
          setAlertText('Code Sent');
          setAlertOpen(true);
          window.recaptchaVerifier = null;
        })
        .catch((error) => {
          console.log('Error Signin Phone Number: ', error);
          setSubmitting(false);
          setAlertText('Mobile Login Error');
          setAlertOpen(true);
          setIsMobileLoginError(true);
        });
    }
  };

  const handlePINSubmit = (
    values: PINValues,
    { setFieldError, setSubmitting }: FormikHelpers<PINValues>
  ) => {
    const { PIN } = values;
    let confirmationResult = window.confirmationResult;

    console.log('Confirmation Result: ', confirmationResult);

    confirmationResult
      .confirm(PIN)
      .then(async (result: any) => {
        console.log('result: ', result);
        if (result) {
          const user = result.user;
          try {
            const token = await user.getIdToken();
            console.log('Token: ', token);
            setMobileToken(token);
            setIsMobileLoginProcessing(true);
          } catch (e: any) {
            console.log('Error Getting Token: ', e);
            setAlertText('Mobile Login Error');
            setIsMobileLoginError(true);
            setAlertOpen(true);
            setSubmitting(false);
          }
        }
      })
      .catch((error: any) => {
        console.log('Error:', error);
        setFieldError('PIN', 'Wrong code');
        setSubmitting(false);
      });
  };

  return (
    <div>
      {alertOpen && (
        <ConfirmationDialog
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          onCancel={() => setAlertOpen(false)}
          onConfirm={() => {
            if (isMobileLoginError) {
              setAlertOpen(false);
              setTabValue(0);
              setIsMobileLoginError(false);
            } else {
              setAlertOpen(false);
            }
          }}
          title="Mobile Login"
          body={alertText}
          confirmButtonText="Ok"
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.content}>
            <div className={classes.logoContainer}>
              <img
                src={merchantLogo ? merchantLogo : Flexlogo}
                className={merchantLogo ? classes.merchantLogo : classes.logo}
                alt="flex reward"
              />
            </div>
            <div className={classes.header}>
              <Typography variant="body1">Login with</Typography>
            </div>
            <div className={classes.tabContainer}>
              <Tabs value={tabValue} aria-label="menu tab">
                <div className={classes.tabWrapper}>
                  <Tab
                    label={'EMAIL'}
                    {...a11yProps(0)}
                    onClick={() => setTabValue(0)}
                  />
                  <div
                    className={
                      tabValue === 0
                        ? classes.selectedTabLiner
                        : classes.tabLiner
                    }
                  />
                </div>
                <Typography variant="body1" className={classes.orLabel}>
                  or
                </Typography>
                <div className={classes.tabWrapper}>
                  <Tab
                    label={'MOBILE'}
                    {...a11yProps(1)}
                    onClick={() => setTabValue(1)}
                  />
                  <div
                    className={
                      tabValue === 1
                        ? classes.selectedTabLiner
                        : classes.tabLiner
                    }
                  />
                </div>
              </Tabs>
            </div>
            <TabPanel value={tabValue} index={0}>
              <Formik
                initialValues={initialEmailValues}
                validationSchema={ValidationEmailSchema}
                onSubmit={handleEmailSubmit}
              >
                {({ isSubmitting }) => (
                  <Form noValidate className={classes.form}>
                    <Field
                      component={TextField}
                      className={classes.textField}
                      name="email"
                      type="email"
                      autoFocus
                      fullWidth
                      variant="standard"
                      placeholder="Email"
                    />

                    <Field
                      component={TextField}
                      className={classes.textField}
                      name="password"
                      type="password"
                      fullWidth
                      variant="standard"
                      placeholder="Password"
                    />

                    <div className={classes.actions}>
                      <Button
                        type="submit"
                        color="primary"
                        size="large"
                        fullWidth
                        disabled={isSubmitting}
                      >
                        Log In
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className={classes.forgetContainer}>
                <Typography variant="body2">
                  <Link component={RouterLink} to="/forgot_password">
                    Forgot Password?
                  </Link>
                </Typography>
              </div>
              <div className={classes.noAccountContainer}>
                <Typography variant="body2">
                  Don't have a Flex Account?{' '}
                  <Link component={RouterLink} to="/signup">
                    Sign up
                  </Link>
                </Typography>
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {isMobileLoginProcessing && (
                <Box display="flex" justifyContent="center">
                  <Spinner />
                </Box>
              )}
              {!verifyingPIN && (
                <Formik
                  initialValues={initialMobileValues}
                  validationSchema={ValidationMobileSchema}
                  onSubmit={handleMobileSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form noValidate className={classes.form}>
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <Field
                            component={Select}
                            name="countryCode"
                            label=""
                            fullWidth
                          >
                            {codes.map((code) => (
                              <MenuItem
                                value={code.isoCode2}
                                key={code.isoCode2}
                              >
                                {'+' + code.countryCodes + ' ' + code.country}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={7}>
                          <Field
                            component={TextField}
                            className={classes.textField}
                            name="mobNum"
                            autoFocus
                            fullWidth
                            variant="standard"
                            placeholder="Mobile Number"
                          />
                        </Grid>
                      </Grid>

                      <div className={classes.actionsMobNum}>
                        <Grid item xs={8}>
                          <Button
                            id="send-code-button"
                            type="submit"
                            color="primary"
                            size="medium"
                            fullWidth
                            disabled={isSubmitting}
                          >
                            Send Code
                          </Button>
                        </Grid>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
              {verifyingPIN && (
                <Formik
                  initialValues={initialPINValues}
                  validationSchema={ValidationPINSchema}
                  onSubmit={handlePINSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form noValidate className={classes.form}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            className={classes.pinHeader}
                          >
                            Enter Verification Code
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            className={classes.textField}
                            label=""
                            name="PIN"
                            fullWidth
                            variant="standard"
                            inputProps={{
                              style: { textAlign: 'center' },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <div className={classes.actionsMobNum}>
                        <Grid item xs={4}>
                          <Button
                            type="submit"
                            color="primary"
                            size="medium"
                            fullWidth
                            disabled={isSubmitting}
                          >
                            Verify
                          </Button>
                        </Grid>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
              <div className={classes.noAccountContainer}>
                <Typography variant="body2">
                  Don't have a Flex Account?{' '}
                  <Link component={RouterLink} to="/signup">
                    Sign up
                  </Link>
                </Typography>
              </div>
            </TabPanel>
          </div>
        </Grid>
      </Grid>
      <div id="recaptcha-container"></div>
    </div>
  );
}
