import request from './request';
import deserialize from './deserialize';

export type Session = {
  id: string;
  token: string;
};

export function signIn(email: string, password: string) {
  return request<Session>({
    url: '/api/v3/login',
    method: 'POST',
    data: {
      data: {
        type: 'login',
        attributes: {
          email,
          password,
        },
      },
    },
    transformResponse: deserialize,
  });
}

export function signUp(
  email: string,
  password: string,
  firstName: string,
  lastName: string
) {
  return request({
    url: '/api/v3/signup',
    method: 'POST',
    data: {
      data: {
        type: 'signup',
        attributes: {
          email,
          password,
          firstName,
          lastName,
        },
      },
    },
    transformResponse: deserialize,
  });
}

export function signOut(token: string) {
  return request({
    url: '/api/v3/logout',
    method: 'DELETE',
    data: {
      data: {
        attributes: {
          token,
        },
      },
    },
    transformResponse: deserialize,
  });
}

export function requestPasswordReset(email: string) {
  return request({
    url: '/api/v3/password/reset',
    method: 'POST',
    data: {
      data: {
        attributes: {
          email,
        },
      },
    },
    transformResponse: deserialize,
  });
}

export function googleLogin(googleToken: string) {
  return request({
    url: '/api/v3/login',
    method: 'POST',
    data: {
      data: {
        type: 'login',
        attributes: {
          googleIDToken: googleToken,
        },
      },
    },
  });
}

export function fbLogin(fbToken: string) {
  return request({
    url: '/api/v3/login',
    method: 'POST',
    data: {
      data: {
        type: 'login',
        attributes: {
          facebookAccessToken: fbToken,
        },
      },
    },
  });
}

export function appleWebLogin(
  token: string,
  firstName: string,
  lastName: string
) {
  return request({
    url: '/api/v3/apple/web/login',
    method: 'POST',
    data: {
      data: {
        type: 'login',
        attributes: {
          authorizationCode: token,
          firstName: firstName,
          lastName: lastName,
        },
      },
    },
  });
}

/**
 * Update password
 *
 * @param password
 */

export function updatePassword(password: string) {
  return request({
    url: '/api/v3/password',
    method: 'PATCH',
    data: {
      data: {
        type: 'users',
        attributes: {
          password,
        },
      },
    },
  });
}

/**
 * Mobile Login the user
 */

export function mobileLogin(mobileNum: string, token: string) {
  return request<Session>({
    url: '/api/v3/login',
    method: 'POST',
    data: {
      data: {
        type: 'login',
        attributes: {
          mobNum: mobileNum,
          firebaseToken: token,
        },
      },
    },
    transformResponse: deserialize,
  });
}
