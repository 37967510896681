import React, { useCallback, useState } from 'react';

import * as deepLinkStorage from '../lib/storage/deeplink';

type Deeplink = deepLinkStorage.Deeplink;

type DeepLinkContextProps = {
  // Deeplink defines the web deeplink
  deeplink: Deeplink | null;
  // setDeeplink sets a new deeplink in context and stores it in local storage
  setDeeplink: (deeplink: Deeplink) => void;
  // removeNav removes the deeplink from context and local storage
  removeDeeplink: () => void;
};

const DeepLinkContext = React.createContext<DeepLinkContextProps | null>(null);

/**
 * DeepLinkContextProvider provides the deeplink context to it's children
 */
export function DeepLinkContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [deeplink, setDeepLinkState] = useState(deepLinkStorage.get());

  const removeDeeplink = useCallback(() => {
    setDeepLinkState(null);
  }, []);

  const setDeeplink = useCallback((s) => {
    setDeepLinkState(s);
  }, []);

  const ctx = {
    deeplink,
    setDeeplink,
    removeDeeplink,
  };

  return (
    <DeepLinkContext.Provider value={ctx}>{children}</DeepLinkContext.Provider>
  );
}

export default DeepLinkContext;
