/**
 * @file Manages the session storage in local storage
 */

export type Session = {
  id: string;
  token: string;
  userId: string;
};

/**
 * Get the current session
 */
export function get(): Session | null {
  const session = window.localStorage.getItem('session');

  if (session) {
    return JSON.parse(session);
  }

  return null;
}

/**
 * Set the session
 *
 * @param session
 */
export function set(session: Session) {
  window.localStorage.setItem('session', JSON.stringify(session));
}

/**
 * Remove the session
 */
export function remove() {
  window.localStorage.removeItem('session');
}
