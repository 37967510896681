import React, { useCallback, useEffect, useState } from 'react';

import * as navStorage from '../lib/storage/nav';

type Nav = navStorage.Nav;

type NavContextProps = {
  // Nav defines the user's nav
  nav: Nav | null;
  // setNav sets a new nav in context and stores it in local storage
  setNav: (nav: Nav) => void;
  // removeNav removes the nav from context and local storage
  removeNav: () => void;
};

const NavContext = React.createContext<NavContextProps | null>(null);

/**
 * NavContextProvider provides the nav context to it's children
 */
export function NavContextProvider({ children }: React.PropsWithChildren<{}>) {
  const [nav, setNavState] = useState(navStorage.get());

  // Persist the token to local storage when the token changes. Remove the token
  // if it is null.
  useEffect(() => {
    if (nav) {
      navStorage.set(nav);
    } else {
      navStorage.remove();
    }
  }, [nav]);

  const removeNav = useCallback(() => {
    setNavState(null);
  }, []);

  const setNav = useCallback((s) => {
    setNavState(s);
  }, []);

  const ctx = {
    nav,
    setNav,
    removeNav,
  };

  return <NavContext.Provider value={ctx}>{children}</NavContext.Provider>;
}

export default NavContext;
