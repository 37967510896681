import React, { useState } from 'react';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// import { Heading3, Heading5 } from '../../components/Typography';
// import { TextBox } from '../FormikAdapter';

// const SMALL_DEVICE_WIDTH = 320;
//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------
export type SelectStampGroupProps = {
  value: string;
  onChange: (value: string) => void;
};

export interface MultiStampFormValues {
  multiStamp?: number;
}

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStampStyles = makeStyles((theme) => ({
  stampContainer: ({ highlight }: { highlight: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[400],
    backgroundColor: highlight
      ? theme.palette.grey[400]
      : theme.palette.common.white,
    width: 40,
    height: 40,
    borderRadius: 5,
    cursor: 'pointer',
  }),
  stampNumber: ({ highlight }: { highlight: boolean }) => ({
    color: highlight ? theme.palette.common.white : theme.palette.grey[400],
    fontWeight: theme.typography.fontWeightMedium,
  }),
}));

const useStyles = makeStyles((theme) => ({
  stampCardRoot: {
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(2.5)}px ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(),
    },
  },
  tile: {
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: 250,
    marginTop: 12,
  },
  buttonContainer: {
    padding: '10px 20px',
  },
  submitButton: {
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'center',
  },
  titleContainer: {
    marginTop: 6,
    marginBottom: 6,
  },
  subTitleContainer: {
    marginTop: 6,
    marginBottom: 12,
  },
}));

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  multiStamp: Yup.number()
    .required('Required')
    .integer('Only whole number')
    .moreThan(9, 'Must be greater than 9')
    .lessThan(21, 'Max 20'),
});

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export function Stamp({
  value,
  highlight,
  handleClick,
}: {
  value: string;
  highlight: boolean;
  handleClick: () => void;
}) {
  const classes = useStampStyles({ highlight });

  return (
    <div className={classes.stampContainer} onClick={handleClick}>
      <Typography variant="subtitle2" className={classes.stampNumber}>
        {value}
      </Typography>
    </div>
  );
}

export default function SelectStampGroup({
  value,
  onChange,
}: SelectStampGroupProps) {
  const classes = useStyles();
  const initialValues: MultiStampFormValues = {
    multiStamp: undefined,
  };
  const [selectedStamp, setSelectedStamp] = useState(value);
  const [showMoreMultiStamp, setShowMoreMultiStamp] = useState(false);
  const [stampArray, setStampArray] = useState([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '+',
  ]);

  const onSubmit = (values: MultiStampFormValues) => {
    if (values.multiStamp) {
      let updateStampArray = stampArray;
      updateStampArray.pop();
      updateStampArray.push(values.multiStamp.toString());
      setStampArray(updateStampArray);
      setSelectedStamp(values.multiStamp.toString());
      onChange(values.multiStamp.toString());
      setShowMoreMultiStamp(false);
    }
  };

  const renderStamps = () =>
    stampArray.map((stmp, idx) => (
      <ImageListItem key={idx} cols={1} rows={1} className={classes.tile}>
        <Stamp
          value={stmp}
          highlight={stmp === selectedStamp}
          handleClick={() => {
            setSelectedStamp(stmp);
            if (stmp !== '+') {
              setShowMoreMultiStamp(false);
              onChange(stmp);
            }
            if (stmp === '+') {
              setShowMoreMultiStamp(true);
            }
          }}
        />
      </ImageListItem>
    ));

  return (
    <div className={classes.root}>
      <ImageList rowHeight={40} cols={5} gap={12}>
        {renderStamps()}
      </ImageList>
      {showMoreMultiStamp && (
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <div className={classes.container}>
              <div className={classes.titleContainer}>
                <Typography variant="body2">Select Other Number</Typography>
              </div>
              <div className={classes.subTitleContainer}>
                <Typography variant="body2">(Max 20)</Typography>
              </div>
              <Field
                component={TextField}
                name="multiStamp"
                autoFocus
                keyboardType="number-pad"
              />
              <div className={classes.buttonContainer}>
                <Button
                  onClick={() => handleSubmit()}
                  className={classes.submitButton}
                >
                  Select
                </Button>
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
}
