import React from 'react';
import codes from 'country-calling-code';
import moment from 'moment';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import DatePicker from '../FormikAdapter/DatePicker';
import Selector from '../FormikAdapter/Selector';
import { find } from 'lodash';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DialogPaper from '../DialogPaper';

import * as ProfileService from '../../api/profileService';

export interface Values {
  firstName: string;
  lastName: string;
  mobNum: string;
  email: string;
  altEmail: string;
  countryCode: string;
  dob: moment.Moment | null;
  company: string;
  reqFullName: boolean;
  reqEmail: boolean;
  reqMobNum: boolean;
  reqAltEmail: boolean;
  reqDOB: boolean;
  reqCompany: boolean;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    width: 100,
    margin: theme.spacing(),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  toolbar: {
    display: 'flex',
    paddingLeft: 12,
    paddingRight: 12,
  },
  box: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  close: {
    marginRight: 'auto',
  },
  actions: {
    marginLeft: 'auto',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  closeIcon: {
    color: theme.palette.common.black,
  },
  merchantLogo: {
    height: 120,
    width: 120,
    borderRadius: '50%',
    objectFit: 'cover',
    boxShadow: '0 2px 10px rgba(199, 199, 199, 0.5)',
  },
  logoContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = Pick<DialogProps, 'open' | 'onClose'> & {
  profile: ProfileService.Profile;
  confirmUpdate: (status: boolean) => void;
  requestFullName: boolean;
  requestMobile: boolean;
  requestEmail: boolean;
  requestAltEmail: boolean;
  requestDOB: boolean;
  requestCompany: boolean;
  logo: string;
};

export default function EditProfileDialog({
  profile,
  onClose,
  open,
  confirmUpdate,
  requestFullName,
  requestMobile,
  requestEmail,
  requestAltEmail,
  requestDOB,
  requestCompany,
  logo,
}: Props) {
  const containsPrivateRelayAddress = (value: string) =>
    value.includes('@privaterelay.appleid.com') ? true : false;
  const classes = useStyles();
  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().when('reqFullName', {
      is: (reqFullName: boolean) => reqFullName === true,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    lastName: Yup.string().when('reqFullName', {
      is: (reqFullName: boolean) => reqFullName === true,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    mobNum: Yup.string().when('reqMobNum', {
      is: (reqMobNum: boolean) => reqMobNum === true,
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    email: Yup.string().when('reqEmail', {
      is: (reqEmail: boolean) => reqEmail === true,
      then: Yup.string().required('Required').email('Not a valid email'),
      otherwise: Yup.string().nullable(),
    }),
    altEmail: Yup.string().when(['reqAltEmail'], {
      is: (reqAltEmail: boolean) => reqAltEmail === true,
      then: Yup.string()
        .required('Required')
        .email('Not a valid email')
        .test(
          'excludePrivateRelayAddress',
          'Should not contain private relay address',
          (value) => (value ? !containsPrivateRelayAddress(value) : false)
        ),
      otherwise: Yup.string().nullable(),
    }),
    dob: Yup.string().when('reqDOB', {
      is: (reqDOB: boolean) => reqDOB === true,
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    company: Yup.string().when('reqCompany', {
      is: (reqCompany: boolean) => reqCompany === true,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
  });

  const initialValues: Values = {
    firstName: profile.firstName,
    lastName: profile.lastName,
    mobNum: profile.mobNum,
    email: profile.email,
    altEmail: profile.alt_email,
    countryCode: 'US',
    dob:
      !profile.dob || profile.dob === -62135596800
        ? null
        : moment.unix(profile.dob),
    company: profile.company,
    reqFullName: requestFullName,
    reqEmail: requestEmail,
    reqMobNum: requestMobile,
    reqAltEmail: requestAltEmail,
    reqDOB: requestDOB,
    reqCompany: requestCompany,
  };

  const handleSubmit = async (
    values: Values,
    { setFieldError, setSubmitting }: FormikHelpers<Values>
  ) => {
    try {
      let callingCode = undefined;
      if (values.reqMobNum && profile.email) {
        callingCode = find(codes, function (code) {
          return code.isoCode2 === values.countryCode;
        });
      }

      await ProfileService.update(
        profile.gender,
        !values.reqDOB
          ? moment.unix(profile.dob).format('YYYY-MM-DD')
          : values.reqDOB && values.dob
          ? values.dob.format('YYYY-MM-DD')
          : null,
        !values.reqFullName
          ? profile.firstName
          : values.reqFullName && !profile.firstName
          ? values.firstName
          : '',
        !values.reqFullName
          ? profile.lastName
          : values.reqFullName && !profile.lastName
          ? values.lastName
          : '',
        !values.reqEmail
          ? profile.email
          : values.reqEmail && profile.mobNum
          ? values.email.toLowerCase()
          : '',
        !values.reqMobNum
          ? profile.mobNum
          : values.reqMobNum && profile.email && callingCode
          ? '+' + callingCode.countryCodes + ' ' + values.mobNum.trim()
          : '',
        !values.reqAltEmail ? profile.alt_email : values.altEmail.toLowerCase(),
        !values.reqCompany ? profile.company : values.company
      );
      confirmUpdate(true);
      setSubmitting(false);
    } catch (e: any) {
      console.log('e', e);
      e.data.forEach((err: any) => {
        console.log('err', err);
        if (err.meta.attribute === 'mobile') {
          setFieldError('mobNum', err.detail);
        } else {
          setFieldError(err.meta.attribute, err.detail);
        }
      });
      confirmUpdate(false);
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperComponent={DialogPaper}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty }) => {
          return (
            <Form noValidate>
              <Toolbar className={classes.toolbar}>
                <div className={classes.box}>
                  <div className={classes.close}>
                    <IconButton onClick={handleClose}>
                      <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                  </div>
                </div>
                <div className={classes.box}>
                  <div className={classes.actions}></div>
                </div>
              </Toolbar>
              <div className={classes.logoContainer}>
                <img
                  src={logo}
                  className={classes.merchantLogo}
                  alt="Merchant Logo"
                />
              </div>
              <div className={classes.box}>
                <Typography variant="h6" className={classes.title}>
                  Please complete your profile
                </Typography>
              </div>
              <Container maxWidth="md">
                <div className={classes.cardContainer}>
                  {requestFullName && (
                    <>
                      <Field
                        component={TextField}
                        className={classes.textField}
                        name="firstName"
                        label="First Name"
                        autoFocus
                        variant="standard"
                      />
                      <Field
                        component={TextField}
                        className={classes.textField}
                        name="lastName"
                        label="Last Name"
                        autoFocus
                        variant="standard"
                      />
                    </>
                  )}
                  {requestCompany && (
                    <Field
                      component={TextField}
                      className={classes.textField}
                      name="company"
                      label="Company"
                      autoFocus
                      variant="standard"
                      placeholder="Type NA if it is not applicable"
                    />
                  )}
                  {requestEmail && (
                    <Field
                      component={TextField}
                      className={classes.textField}
                      name="email"
                      label="Email"
                      autoFocus
                      variant="standard"
                      inputProps={{ style: { textTransform: 'lowercase' } }}
                    />
                  )}
                  {requestAltEmail && (
                    <Field
                      component={TextField}
                      className={classes.textField}
                      name="altEmail"
                      label="Alternate Email"
                      autoFocus
                      variant="standard"
                      inputProps={{ style: { textTransform: 'lowercase' } }}
                    />
                  )}
                  {requestMobile && (
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <Field
                          component={Selector}
                          name="countryCode"
                          label="Mobile"
                          fullWidth
                        >
                          {codes.map((code) => (
                            <MenuItem value={code.isoCode2} key={code.isoCode2}>
                              {'+' + code.countryCodes + ' ' + code.country}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={7}>
                        <Field
                          component={TextField}
                          className={classes.textField}
                          label=" "
                          name="mobNum"
                          autoFocus
                          fullWidth
                          variant="standard"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  )}
                  {requestDOB && (
                    <Field
                      component={DatePicker}
                      className={classes.textField}
                      name="dob"
                      label="Birthday"
                      format="DD/MM/YYYY"
                      fullWidth
                      initvalue={profile.dob ? moment.unix(profile.dob) : null}
                    />
                  )}
                  <div className={classes.actionContainer}>
                    <Button
                      type="submit"
                      color="primary"
                      className={classes.buttonContainer}
                      disabled={isSubmitting || !dirty}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
