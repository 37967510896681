import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import AppleSignin from 'react-apple-signin-auth';
import { useGoogleLogin } from '@react-oauth/google';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Flexlogo from '../../assets/images/logo-red.png';

import * as AuthService from '../../api/authService';

import useAuthContext from '../../hooks/useAuthContext';
import useNavContext from '../../hooks/useNavContext';

import useDeepLinkContext from '../../hooks/useDeepLinkContext';
import GoogleButton from './GoogleButton';
import AppleButton from './AppleButton';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: 400,
      margin: '0 auto',
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      margin: '0 auto',
      marginTop: theme.spacing(5),
    },
  },
  form: {
    marginTop: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  socialAction: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(),
  },
  actionButton: {
    width: 120,
  },
  disclaimer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  logoContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 140,
    height: 50,
  },
  merchantLogo: {
    height: 120,
    width: 120,
    borderRadius: '50%',
    objectFit: 'cover',
    boxShadow: '0 2px 10px rgba(199, 199, 199, 0.5)',
  },
  facebookButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#3578e5',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#365899',
      color: '#fff',
    },
  },
  caption: {
    lineHeight: '12px',
  },
}));

/**
 * Root redirects to the user's companies dashboard.
 */
export default function MainSplash() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const feature = params['feature'];
  const merchantId = params['merchant'];
  const contactlessCode = params['code'];
  const couponCode = params['code'];
  const merchantCode = params['merchant'];
  const userCode = params['user'];
  const singleUseCode = params['code'];
  const store = params['store'];
  const stampNum = params['numOfStamps'];
  const logo = params['logo'] as string;
  const merchantName = params['name'];
  const [isLoading, setIsLoading] = useState(true);
  const agreement1 = true;

  const dl = useDeepLinkContext();
  const auth = useAuthContext();
  const nav = useNavContext();

  const googleWebClient = process.env.REACT_APP_GOOGLE_OAUTH_WEB_CLIENT;
  const appleClient = process.env.REACT_APP_APPLE_CLIENT;
  const appleRedirectURI = process.env.REACT_APP_APPLE_REDIRECT_URI;

  useEffect(() => {
    const setDeeplink = () => {
      if (dl) {
        dl.setDeeplink({
          feature: feature && typeof feature == 'string' ? feature : undefined,
          merchantId:
            merchantId && typeof merchantId == 'string'
              ? merchantId
              : undefined,
          contactlessCode:
            contactlessCode && typeof contactlessCode == 'string'
              ? contactlessCode
              : undefined,
          couponCode:
            couponCode && typeof couponCode == 'string'
              ? couponCode
              : undefined,
          merchantCode:
            merchantCode && typeof merchantCode == 'string'
              ? merchantCode
              : undefined,
          userCode:
            userCode && typeof userCode == 'string' ? userCode : undefined,
          logo: logo && typeof logo == 'string' ? logo : undefined,
          singleUseCode:
            singleUseCode && typeof singleUseCode == 'string'
              ? singleUseCode
              : undefined,
          store: store && typeof store == 'string' ? store : undefined,
          stampNum:
            stampNum && typeof stampNum == 'string' ? stampNum : undefined,
        });
      }
      setIsLoading(false);
    };

    if (isLoading) {
      setDeeplink();
    }
  }, [
    isLoading,
    feature,
    merchantId,
    contactlessCode,
    couponCode,
    merchantCode,
    userCode,
    dl,
    logo,
    singleUseCode,
    store,
    stampNum,
  ]);

  const deepLinkHandler = () => {
    if (dl) {
      dl.setDeeplink({
        feature: feature && typeof feature == 'string' ? feature : undefined,
        merchantId:
          merchantId && typeof merchantId == 'string' ? merchantId : undefined,
        contactlessCode:
          contactlessCode && typeof contactlessCode == 'string'
            ? contactlessCode
            : undefined,
        couponCode:
          couponCode && typeof couponCode == 'string' ? couponCode : undefined,
        merchantCode:
          merchantCode && typeof merchantCode == 'string'
            ? merchantCode
            : undefined,
        userCode:
          userCode && typeof userCode == 'string' ? userCode : undefined,
        logo: logo && typeof logo == 'string' ? logo : undefined,
      });
    }
  };

  const handleGoogleLogin = async (googleToken: string) => {
    try {
      const res: any = await AuthService.googleLogin(googleToken);
      console.log('res: ', res);
      const { token } = res.data.attributes;

      auth?.setSession({
        id: res.data.id,
        token: token,
        userId: '1',
      });
      if (feature) {
        deepLinkHandler();
        history.go(0);
      } else {
        nav?.setNav({
          menuNav: 'home',
        });
        history.push('/');
        history.go(0);
      }
    } catch (e: any) {
      console.log('error: ', e);
    }
  };

  const handleAppleLogin = async (
    appleToken: string,
    firstName: string,
    lastName: string
  ) => {
    try {
      const res: any = await AuthService.appleWebLogin(
        appleToken,
        firstName,
        lastName
      );

      console.log('res: ', res);
      const { token } = res.data.attributes;

      auth?.setSession({
        id: res.data.id,
        token: token,
        userId: '1',
      });

      if (feature) {
        deepLinkHandler();
        history.go(0);
      } else {
        nav?.setNav({
          menuNav: 'home',
        });
        history.push('/');
        history.go(0);
      }
    } catch (e: any) {
      console.log('error: ', e);
    }
  };

  const responseGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      handleGoogleLogin(codeResponse.access_token);
    },
  });

  const responseApple = (response: any) => {
    if (response && response.authorization && response.authorization.code) {
      const code = response.authorization.code;
      const firstName =
        response.user && response.user.name && response.user.name.firstName
          ? response.user.name.firstName
          : '';
      const lastName =
        response.user && response.user.name && response.user.name.lasstName
          ? response.user.name.lastName
          : '';
      handleAppleLogin(code, firstName, lastName);
    }
    console.log(response);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.content}>
            <div className={classes.logoContainer}>
              <img
                src={logo ? logo : Flexlogo}
                className={logo ? classes.merchantLogo : classes.logo}
                alt="flex reward"
              />
            </div>
            <div className={classes.socialAction}>
              {appleClient && appleRedirectURI && (
                <AppleSignin
                  authOptions={{
                    clientId: appleClient,
                    scope: 'email name',
                    redirectURI: appleRedirectURI,
                    state: 'origin:web',
                    nonce: ' nonce',
                    usePopup: true,
                  }}
                  onSuccess={responseApple}
                  render={(props: any) => (
                    <AppleButton
                      onClick={props.onClick}
                      disabled={!agreement1}
                    />
                  )}
                />
              )}
              {googleWebClient && (
                <GoogleButton
                  onClick={() => responseGoogle()}
                  disabled={!agreement1}
                />
              )}
            </div>
            <div className={classes.actions}>
              <Button
                variant="outlined"
                size="large"
                component={RouterLink}
                to={`/login`}
                className={classes.actionButton}
                disabled={!agreement1}
              >
                Log In
              </Button>
              <Button
                variant="outlined"
                size="large"
                component={RouterLink}
                to={`/signup`}
                className={classes.actionButton}
                disabled={!agreement1}
              >
                Sign Up
              </Button>
            </div>
            <div className={classes.disclaimer}>
              <div>
                <Typography variant="caption" className={classes.caption}>
                  By continuing, I agree to Flex Rewards'&nbsp;
                </Typography>
                <Typography variant="caption" className={classes.caption}>
                  <Link
                    href={'https://myflexrewards.com/terms/'}
                    target="blank"
                  >
                    Terms of Service
                  </Link>
                </Typography>

                <Typography variant="caption" className={classes.caption}>
                  &nbsp;and&nbsp;
                </Typography>
                <Typography variant="caption" className={classes.caption}>
                  <Link
                    href={'https://myflexrewards.com/privacy/'}
                    target="blank"
                  >
                    Privacy Policy
                  </Link>
                </Typography>
                <Typography variant="caption" className={classes.caption}>
                  &nbsp;and consent to the release of my email and number to{' '}
                  {merchantName ? merchantName : 'participating merchants'} for
                  communications and marketing purposes.
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
