import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import * as StoreService from '../../api/storeService';
import * as StampCardService from '../../api/stampCardService';

import useNavContext from '../../hooks/useNavContext';

import Spinner from '../../components/Spinner';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import AddStampDialog from '../../components/Dialogs/AddStampDialog';
import AddStampSuccessDialog from '../../components/Dialogs/AddStampSuccessDialog';

interface RouteParams {
  contactlessCode: string;
}

/**
 * Merchant
 */
export default function RedeemContactlessScreen() {
  const { contactlessCode } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [launchAlertDialog, setLaunchAlertDialog] = useState(false);
  const [stampSuccessDialog, setStampSuccessDialog] = useState(false);
  const [launchAddStampDialog, setLaunchAddStampDialog] = useState(false);
  const [stampCard, setStampCard] =
    useState<StampCardService.StampCard | null>();
  const [merchantId, setMerchantId] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [addedStamp, setAddedStamp] = useState(1);
  const nav = useNavContext();
  nav?.removeNav();

  const history = useHistory();

  useEffect(() => {
    const fetchStampCard = async () => {
      try {
        const resStampCard = await StampCardService.getByMerchantId(merchantId);
        setStampCard(resStampCard);
      } catch (e) {
        console.log('Error: ', e);
      }
    };

    if (merchantId) {
      fetchStampCard();
    }
  }, [merchantId]);

  useEffect(() => {
    let subscribed = true;

    const redeem = async () => {
      if (subscribed) {
        const resStore = await StoreService.getStoreByContactlessCode(
          contactlessCode
        );

        setMerchantId(resStore.merchant.id);
        try {
          const resRedeem = await StampCardService.creditStampCardContactless(
            contactlessCode
          );
          if (resRedeem) {
            console.log('ResRedeem: ', resRedeem);
            setStampCard(resRedeem);
            setIsLoading(false);
            setStampSuccessDialog(true);
          }
        } catch (e) {
          const resStampCard = await StampCardService.getByMerchantId(
            resStore.merchant.id
          );
          if (resStampCard) {
            setStampCard(resStampCard);
            setErrorMsg(
              'To collect more than 1 stamp, please pass your device to a staff member.'
            );
            setIsLoading(false);
            setLaunchAlertDialog(true);
          }
        }
      }
    };

    if (isLoading) {
      redeem();
    }

    return () => {
      subscribed = false;
    };
  }, [isLoading, contactlessCode]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 200,
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {merchantId && (
            <>
              <ConfirmationDialog
                open={launchAlertDialog}
                onClose={() => {
                  setLaunchAlertDialog(false);
                  history.replace(`/merchant/${merchantId}`);
                  window.location.reload();
                }}
                onCancel={() => {
                  setLaunchAlertDialog(false);
                  history.replace(`/merchant/${merchantId}`);
                  window.location.reload();
                }}
                onConfirm={() => {
                  setLaunchAlertDialog(false);
                  setLaunchAddStampDialog(true);
                }}
                title="Error"
                body={errorMsg}
                cancelButtonText="CANCEL"
                confirmButtonText="OK"
              />
              {stampCard && merchantId && (
                <>
                  <AddStampDialog
                    stampCardId={stampCard.id}
                    open={launchAddStampDialog}
                    onClose={() => {
                      setLaunchAddStampDialog(false);
                      history.replace(`/merchant/${merchantId}`);
                      window.location.reload();
                    }}
                    confirmRedeem={(addedStamp: number) => {
                      setAddedStamp(addedStamp);
                      setLaunchAddStampDialog(false);
                      setStampSuccessDialog(true);
                    }}
                  />
                  <AddStampSuccessDialog
                    open={stampSuccessDialog}
                    onClose={() => {
                      setStampSuccessDialog(false);
                      history.replace(`/merchant/${merchantId}`);
                      window.location.reload();
                    }}
                    campaignId={stampCard.loyalty_campaign.id}
                    quantity={stampCard.quantity}
                    isMultipleStamp={addedStamp > 1}
                    stampAdded={addedStamp.toString()}
                    allowAddMoreStamp={true}
                    handleAddMoreStamp={() => {
                      setStampSuccessDialog(false);
                      setLaunchAddStampDialog(true);
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
