import React, { useCallback, useEffect, useState } from 'react';
import {
  addAuthenticationInterceptor,
  removeAuthenticationInterceptor,
} from '../api';

import * as sessionStorage from '../lib/storage/session';

type Session = sessionStorage.Session;

type AuthContextProps = {
  // isReady ensures the provider has loaded from session storage and is ready
  isReady: boolean;
  // isAuthenticated checks if the user has a session
  isAuthenticated: boolean;
  // Session defines the user's session
  session: Session | null;
  // setSession sets a new session in context and stores it in local storage
  setSession: (session: Session) => void;
  // removeSession removes the session from context and local storage
  removeSession: () => void;
};

const AuthContext = React.createContext<AuthContextProps | null>(null);

/**
 * AuthContextProvider provides the auth context to it's children
 */
export function AuthContextProvider({ children }: React.PropsWithChildren<{}>) {
  const [session, setSessionState] = useState(sessionStorage.get());
  const [isReady, setIsReady] = useState(false);

  // Persist the token to local storage when the token changes. Remove the token
  // if it is null.
  useEffect(() => {
    if (session) {
      sessionStorage.set(session);
    } else {
      sessionStorage.remove();
    }
  }, [session]);

  // Update the API client to include the JWT in the request headers
  useEffect(() => {
    if (session) {
      addAuthenticationInterceptor(session.token);
    } else {
      removeAuthenticationInterceptor();
    }
    setIsReady(true);
  }, [session]);

  const removeSession = useCallback(() => {
    setSessionState(null);
  }, []);

  const setSession = useCallback((s) => {
    setSessionState(s);
  }, []);

  const ctx = {
    isReady,
    isAuthenticated: !!session,
    session,
    setSession,
    removeSession,
  };

  return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>;
}

export default AuthContext;
