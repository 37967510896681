import React from 'react';

import Fade from '@material-ui/core/Fade';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

export type FullScreenDialogProps = DialogProps;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props: DialogProps) {
  return (
    <Dialog {...props} fullScreen={true} TransitionComponent={Transition}>
      <Fade in={props.open}>
        <div>{props.children}</div>
      </Fade>
    </Dialog>
  );
}
