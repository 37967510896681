import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import useDeepLinkContext from './useDeepLinkContext';
import * as StampCardService from '../api/stampCardService';

export default function useDeepLinkHandler() {
  const dl = useDeepLinkContext();
  const history = useHistory();

  const handleSingleUseCode = useCallback(
    async (
      singleUseCode: string,
      merchantId: string,
      stampNum: string,
      storeId: string
    ) => {
      if (singleUseCode && storeId && stampNum && merchantId) {
        try {
          const resRedeem = await StampCardService.creditStampCardSingleUse(
            singleUseCode,
            storeId,
            parseInt(stampNum),
            merchantId
          );
          if (resRedeem && resRedeem.id) {
            history.replace(
              `/merchant/${merchantId}/stampNum/${stampNum}/singleUseRedeem/status/success/msg/ok`
            );
            history.go(0);
          }
        } catch (e) {
          if (e && typeof e === 'object') {
            console.log(JSON.stringify(e));
            history.replace(
              `/merchant/${merchantId}/stampNum/${stampNum}/singleUseRedeem/status/fail/msg/${e.data[0].detail}`
            );
            history.go(0);
          }
        }
      }
    },
    [history]
  );

  useEffect(() => {
    if (dl && dl.deeplink) {
      switch (dl.deeplink.feature) {
        case 'merchant':
          history.replace(`/join_merchant/${dl.deeplink.merchantId}`);
          dl.removeDeeplink();
          history.go(0);

          break;
        case 'contactless':
          history.replace(`/contactless/${dl.deeplink.contactlessCode}`);
          dl.removeDeeplink();
          history.go(0);

          break;
        case 'coupon':
          history.replace(`/coupon/${dl.deeplink.couponCode}`);
          dl.removeDeeplink();
          history.go(0);

          break;
        case 'singleUse':
          if (
            dl.deeplink.singleUseCode &&
            dl.deeplink.merchantId &&
            dl.deeplink.stampNum &&
            dl.deeplink.store
          ) {
            handleSingleUseCode(
              dl.deeplink.singleUseCode,
              dl.deeplink.merchantId,
              dl.deeplink.stampNum,
              dl.deeplink.store
            );
            // history.replace(
            //   `/singleuse/${dl.deeplink.singleUseCode}/merchant/${dl.deeplink.merchantId}/stampNum/${dl.deeplink.stampNum}/store/${dl.deeplink.store}`
            // );
            // dl.removeDeeplink();
            // history.go(0);
          }

          break;
        case 'referral':
          history.replace(
            `/referral/merchantCode/${dl.deeplink.merchantCode}/userCode/${dl.deeplink.userCode}`
          );
          dl.removeDeeplink();
          history.go(0);

          break;
      }
    }
  }, [dl, history, handleSingleUseCode]);
}
