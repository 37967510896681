import React from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DialogPaper from '../DialogPaper';

import * as StampCardService from '../../api/stampCardService';
import StampGroupSelector from '../FormikAdapter/StampGroupSelector';

export interface Values {
  pin: string;
  stampNum: string;
}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    width: 150,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: 300,
  },
  title: {
    marginTop: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
  },
  subtext: {
    marginTop: theme.spacing(2),
    color: '#6E6E6E',
    textAlign: 'center',
    padding: theme.spacing(),
  },
  avatar: {
    height: 80,
    width: 80,
    boxShadow: '0 2px 10px rgba(199, 199, 199, 0.5)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
}));

type Props = Pick<DialogProps, 'open' | 'onClose'> & {
  merchantLogo?: string;
  confirmRedeem: (addedStamp: number) => void;
  stampCardId: string;
};

export default function AddStampDialog({
  onClose,
  open,
  confirmRedeem,
  stampCardId,
}: Props) {
  const classes = useStyles();
  const ValidationSchema = Yup.object().shape({
    pin: Yup.string().required('Required'),
  });

  const initialValues: Values = {
    pin: '',
    stampNum: '1',
  };

  const handleSubmit = async (values: Values, _: FormikHelpers<Values>) => {
    try {
      let stampAdded = parseInt(values.stampNum, 10);
      await StampCardService.creditStampCard(
        stampCardId,
        values.pin,
        stampAdded
      );
      setTimeout(() => confirmRedeem(stampAdded), 100);
    } catch (e) {
      _.setFieldError('pin', 'Invalid PIN');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} PaperComponent={DialogPaper}>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={false}
      >
        {({ isSubmitting, values: { pin } }) => {
          return (
            <Form noValidate autoComplete="off">
              <div className={classes.cardContainer}>
                <Typography variant="h5" className={classes.title} gutterBottom>
                  Add Stamps
                </Typography>
                <Field component={StampGroupSelector} name="stampNum" />
                <Typography
                  variant="body2"
                  className={classes.subtext}
                  gutterBottom
                >
                  {isSubmitting
                    ? 'Verifying'
                    : 'Staff must validate the addition of stamps'}
                </Typography>
                <Field
                  component={TextField}
                  className={classes.textField}
                  name="pin"
                  type="password"
                  variant="standard"
                  inputProps={{
                    inputMode: 'password',
                    maxLength: 4,
                    style: { textAlign: 'center' },
                  }}
                  autoFocus
                />
                <Button
                  type="submit"
                  color="primary"
                  className={classes.buttonContainer}
                >
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
