import React from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DialogPaper from '../DialogPaper';

import * as AuthService from '../../api/authService';

export interface Values {
  newPassword: string;
  repeatPassword: string;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    width: 100,
    margin: theme.spacing(),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  toolbar: {
    display: 'flex',
    paddingLeft: 12,
    paddingRight: 12,
  },
  box: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  close: {
    marginRight: 'auto',
  },
  actions: {
    marginLeft: 'auto',
  },
  closeIcon: {
    color: theme.palette.common.black,
  },
}));

type Props = Pick<DialogProps, 'open' | 'onClose'> & {
  confirmUpdate: () => void;
};

export default function UpdatePasswordDialog({
  onClose,
  open,
  confirmUpdate,
}: Props) {
  const classes = useStyles();
  const ValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('Required')
      .min(8, 'Must be greater than 8 characters'),
    repeatPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'Passwords must match'
    ),
  });

  const initialValues: Values = {
    newPassword: '',
    repeatPassword: '',
  };

  const handleSubmit = async (values: Values, _: FormikHelpers<Values>) => {
    if (values.newPassword && values.repeatPassword) {
      try {
        await AuthService.updatePassword(values.newPassword);
        confirmUpdate();
      } catch (e) {
        console.log(e);
        confirmUpdate();
      }
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperComponent={DialogPaper}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={false}
      >
        {({ isSubmitting, dirty }) => {
          return (
            <Form noValidate>
              <AppBar className={classes.appBar} position="sticky">
                <Toolbar className={classes.toolbar}>
                  <div className={classes.box}>
                    <div className={classes.close}>
                      <IconButton onClick={handleClose}>
                        <CloseIcon className={classes.closeIcon} />
                      </IconButton>
                    </div>
                  </div>
                  <div className={classes.box}>
                    <Typography variant="h6" className={classes.title}>
                      Update Password
                    </Typography>
                  </div>
                  <div className={classes.box}>
                    <div className={classes.actions}></div>
                  </div>
                </Toolbar>
              </AppBar>
              <Container maxWidth="md">
                <div className={classes.cardContainer}>
                  <Field
                    component={TextField}
                    className={classes.textField}
                    name="newPassword"
                    label="New Password"
                    type="password"
                    variant="standard"
                  />
                  <Field
                    component={TextField}
                    className={classes.textField}
                    name="repeatPassword"
                    label="Confirm Password"
                    type="password"
                    variant="standard"
                  />
                  <div className={classes.actionContainer}>
                    <Button
                      onClick={() => {
                        if (onClose) {
                          onClose({}, 'backdropClick');
                        }
                      }}
                      className={classes.buttonContainer}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className={classes.buttonContainer}
                      disabled={isSubmitting || !dirty}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
