import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridMessageBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  errorMsg: {
    padding: 20,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

/**
 * NotFound displays a 404 Not Found page when the path does not match any
 * routes.
 */
export default function NotFound() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={2} />
        <Grid item xs={8} className={classes.gridMessageBox}>
          <Typography variant="h4">Sorry, page not found</Typography>
          <Typography variant="subtitle1" className={classes.errorMsg}>
            Please ask staff for assistance or{' '}
            <a href="mailto:customerservice@myflexrewards.com">contact us</a>{' '}
            for support.
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </div>
  );
}
