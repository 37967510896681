import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import * as CouponService from '../../api/couponService';
import * as LoyaltyRewardService from '../../api/loyaltyRewardsService';
import * as StampCardService from '../../api/stampCardService';

import useNavContext from '../../hooks/useNavContext';

import Spinner from '../../components/Spinner';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import AddStampDialog from '../../components/Dialogs/AddStampDialog';
import AddStampSuccessDialog from '../../components/Dialogs/AddStampSuccessDialog';

interface RouteParams {
  couponCode: string;
}

/**
 * Merchant
 */
export default function RedeemCouponScreen() {
  const { couponCode } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [launchAlertDialog, setLaunchAlertDialog] = useState(false);
  const [launchAddStampDialog, setLaunchAddStampDialog] = useState(false);
  const [stampSuccessDialog, setStampSuccessDialog] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [stampCard, setStampCard] = useState<StampCardService.StampCard | null>(
    null
  );
  const [addedStamp, setAddedStamp] = useState(0);
  const nav = useNavContext();
  nav?.removeNav();

  const history = useHistory();

  useEffect(() => {
    let subscribed = true;

    const redeem = async (merchantId: string) => {
      if (subscribed) {
        try {
          await CouponService.redeem(couponCode);

          try {
            const resStampCard = await StampCardService.getByMerchantId(
              merchantId
            );

            setStampCard(resStampCard);
            setAddedStamp(1);
            setIsLoading(false);
            setStampSuccessDialog(true);
          } catch (e) {
            setIsLoading(false);
            setLaunchAlertDialog(true);
          }
        } catch (e) {
          console.log('Error: ', e);
          setIsLoading(false);
          setLaunchAlertDialog(true);
        }
      }
    };

    const fetchCoupon = async () => {
      const res = await CouponService.get(couponCode);

      if (res.redeemed) {
        setIsLoading(false);
        setLaunchAlertDialog(true);
      } else {
        const resLoyaltyReward = await LoyaltyRewardService.get(
          res.loyaltyCampaign.id
        );

        if (resLoyaltyReward.merchant.id) {
          setMerchantId(resLoyaltyReward.merchant.id);
          redeem(resLoyaltyReward.merchant.id);
        }
      }
    };

    if (couponCode) {
      fetchCoupon();
    }

    return () => {
      subscribed = false;
    };
  }, [couponCode]);

  if (!stampCard) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 200,
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ConfirmationDialog
            open={launchAlertDialog}
            onClose={() => {
              setLaunchAlertDialog(false);
              history.push('/');
            }}
            onCancel={() => {
              setLaunchAlertDialog(false);
              history.push('/');
            }}
            onConfirm={() => {
              setLaunchAlertDialog(false);
              history.push('/');
            }}
            title="Coupon is invalid"
            body="The coupon is invalid or has already been redeemed."
            confirmButtonText="Dismiss"
          />
          {merchantId && (
            <>
              <AddStampDialog
                stampCardId={stampCard.id}
                open={launchAddStampDialog}
                onClose={() => {
                  setLaunchAddStampDialog(false);
                  history.replace(`/merchant/${merchantId}`);
                  window.location.reload();
                }}
                confirmRedeem={(addedStamp: number) => {
                  setAddedStamp(addedStamp);
                  setLaunchAddStampDialog(false);
                  setStampSuccessDialog(true);
                }}
              />
              <AddStampSuccessDialog
                open={stampSuccessDialog}
                onClose={() => {
                  setStampSuccessDialog(false);
                  history.push(`/merchant/${merchantId}`);
                }}
                campaignId={stampCard.loyalty_campaign.id}
                quantity={stampCard.quantity}
                isMultipleStamp={
                  addedStamp === 0 ? stampCard.quantity > 1 : addedStamp > 1
                }
                stampAdded={
                  addedStamp === 0
                    ? stampCard.quantity.toString()
                    : addedStamp.toString()
                }
                allowAddMoreStamp={false}
                handleAddMoreStamp={() => {
                  setStampSuccessDialog(false);
                  setLaunchAddStampDialog(true);
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
