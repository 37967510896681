import React from 'react';
import { Form, Formik, FormikHelpers, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import * as authService from '../../api/authService';
import useAuthContext from '../../hooks/useAuthContext';
import useNavContext from '../../hooks/useNavContext';
import useDeepLinkContext from '../../hooks/useDeepLinkContext';
import Flexlogo from '../../assets/images/logo-red.png';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: 400,
      margin: '0 auto',
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      padding: theme.spacing(),
    },
  },
  form: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(),
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(),
    justifyContent: 'center',
  },
  logoContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 140,
    height: 50,
  },
  merchantLogo: {
    height: 120,
    width: 120,
    borderRadius: '50%',
    objectFit: 'cover',
    boxShadow: '0 2px 10px rgba(199, 199, 199, 0.5)',
  },
}));

interface Values {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Not a valid email'),
  password: Yup.string()
    .required('Required')
    .min(8, 'Must be greater than 8 characters'),
});

const initialValues: Values = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

/**
 * SignUp displays the sign up page.
 */
export default function SignUp() {
  const classes = useStyles();
  const auth = useAuthContext();
  const nav = useNavContext();
  const dl = useDeepLinkContext();
  const merchantLogo = dl?.deeplink?.logo;

  const handleSubmit = async (
    values: Values,
    { setFieldError, setSubmitting }: FormikHelpers<Values>
  ) => {
    const { email, password, firstName, lastName } = values;

    try {
      // Sign Up
      await authService.signUp(
        email.toLowerCase(),
        password,
        firstName,
        lastName
      );

      // Sign In
      const res = await authService.signIn(email, password);

      auth?.setSession({
        id: res.id,
        token: res.token,
        userId: '1',
      });

      nav?.setNav({
        menuNav: 'home',
      });
    } catch (e: any) {
      e.data.forEach((err: any) => {
        setFieldError(err.meta.attribute, err.detail);
      });

      setSubmitting(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.content}>
          <div className={classes.logoContainer}>
            <img
              src={merchantLogo ? merchantLogo : Flexlogo}
              className={merchantLogo ? classes.merchantLogo : classes.logo}
              alt="flex reward"
            />
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form noValidate className={classes.form}>
                <Field
                  component={TextField}
                  className={classes.textField}
                  name="firstName"
                  fullWidth
                  variant="standard"
                  placeholder="First Name"
                />

                <Field
                  component={TextField}
                  className={classes.textField}
                  name="lastName"
                  fullWidth
                  variant="standard"
                  placeholder="Last Name"
                />

                <Field
                  component={TextField}
                  className={classes.textField}
                  name="email"
                  type="email"
                  fullWidth
                  variant="standard"
                  placeholder="Email"
                />

                <Field
                  component={TextField}
                  className={classes.textField}
                  name="password"
                  type="password"
                  fullWidth
                  variant="standard"
                  placeholder="Password"
                />

                <div className={classes.actions}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                  >
                    Sign Up
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Grid>
    </Grid>
  );
}
