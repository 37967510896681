import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import * as MemberService from '../../api/memberService';
import * as StampCardService from '../../api/stampCardService';

import useNavContext from '../../hooks/useNavContext';

import Spinner from '../../components/Spinner';

interface RouteParams {
  merchantId: string;
}

/**
 * Merchant
 */
export default function JoinMerchantScreen() {
  const { merchantId } = useParams<RouteParams>();
  const history = useHistory();

  const nav = useNavContext();
  nav?.removeNav();

  useEffect(() => {
    const join = async (merchantId: string) => {
      try {
        await StampCardService.create(merchantId);

        setTimeout(() => history.push(`/merchant/${merchantId}`), 1000);
      } catch (e) {
        console.log('Error creating new stamp card');
      }
    };

    const checkMembership = async () => {
      if (merchantId) {
        try {
          await MemberService.get(merchantId);

          history.push(`/merchant/${merchantId}`);
        } catch (e) {
          console.log('No membership');
          join(merchantId);
        }
      }
    };

    if (merchantId) {
      checkMembership();
    }
  }, [merchantId, history]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 200,
      }}
    >
      <Spinner />
    </div>
  );
}
