import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import useAuthContext from '../../hooks/useAuthContext';

/**
 * A PublicRoute redirects an authenticated user to the overview page.
 */
export default function PublicRoute({ children, ...rest }: RouteProps) {
  const auth = useAuthContext();

  return (
    <Route
      {...rest}
      render={(props) =>
        auth?.isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}
