import request from './request';
import deserialize from './deserialize';

type ProfileCompletedStatus = {
  FullName: boolean;
  MobNum: boolean;
  Email: boolean;
  AltEmail: boolean;
  DOB: boolean;
  Company: boolean;
};

export type Profile = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  referralCode: string;
  gender: string;
  dob: number;
  mobNum: string;
  alt_email: string;
  company: string;
  profileCompletedStatus: ProfileCompletedStatus | null;
};

export function get() {
  return request<Profile>({
    url: '/api/v3/profile',
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function update(
  gender: string | null,
  dob: string | null,
  firstName: string,
  lastName: string,
  email: string,
  mobNum: string,
  altEmail: string,
  company: string
) {
  const attributes: any = {};

  gender && (attributes.gender = gender);
  dob && (attributes.dob = dob);
  firstName && (attributes.firstName = firstName);
  lastName && (attributes.lastName = lastName);
  email && (attributes.email = email);
  mobNum && (attributes.mobNum = mobNum);
  altEmail && (attributes.altEmail = altEmail);
  company && (attributes.company = company);

  return request<Profile>({
    url: '/api/v3/profile',
    method: 'PATCH',
    transformResponse: deserialize,
    data: {
      data: {
        type: 'profile',
        attributes,
      },
    },
  });
}
