import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import * as ReferralService from '../../api/referralService';
import * as MerchantService from '../../api/merchantService';
import * as MemberService from '../../api/memberService';
import * as StampCardService from '../../api/stampCardService';

import useNavContext from '../../hooks/useNavContext';

import Spinner from '../../components/Spinner';

interface RouteParams {
  merchantCode: string;
  userCode: string;
}

/**
 * Referral
 */
export default function ReferralScreen() {
  const { merchantCode, userCode } = useParams<RouteParams>();
  const [merchant, setMerchant] = useState<MerchantService.Merchant | null>(
    null
  );
  const history = useHistory();

  const nav = useNavContext();
  nav?.removeNav();

  useEffect(() => {
    const fetchMerchant = async () => {
      try {
        const res = await MerchantService.getByReferralCode(merchantCode);

        setMerchant(res);
      } catch (e) {
        console.log('Error get merchant code');
      }
    };

    if (merchantCode) {
      fetchMerchant();
    }
  }, [merchantCode]);

  useEffect(() => {
    const join = async (merchantId: string) => {
      try {
        const res: any = await ReferralService.issueLoyaltyReferralProgram(
          userCode,
          merchantCode
        );

        if (!res.error) {
          await StampCardService.getByMerchantId(merchantId);
          setTimeout(() => history.push(`/merchant/${merchantId}`), 1500);
        } else {
          if (res.error && res.payload.status === 400) {
            setTimeout(() => history.push(`/merchant/${merchantId}`), 1500);
          }
          if (res.error && res.payload.status === 404) {
            const response: any = await StampCardService.create(merchantId);

            if (!response.error) {
              setTimeout(() => history.push(`/merchant/${merchantId}`), 1500);
            }
          }
        }
      } catch (e) {
        console.log('Error creating new stamp card');
        setTimeout(() => history.push(`/`), 1500);
      }
    };

    const checkMembership = async () => {
      if (merchant && merchant.id) {
        try {
          await MemberService.get(merchant.id);

          history.push(`/merchant/${merchant.id}`);
        } catch (e) {
          console.log('No membership');
          join(merchant.id);
        }
      }
    };

    if (merchant && merchant.id) {
      checkMembership();
    }
  }, [merchant, userCode, merchantCode, history]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 200,
      }}
    >
      <Spinner />
    </div>
  );
}
