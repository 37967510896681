import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import filter from 'lodash/filter';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import AboutCard from '../../components/Card/AboutCard';
import StampCard from '../../components/Card/StampCard';
import RewardCard from '../../components/Card/RewardCard';
import RedeemedRewardCard from '../../components/Card/RedeemedRewardCard';
import ReferralCard from '../../components/Card/ReferralCard';
import StoreCard from '../../components/Card/StoreCard';
import useNavContext from '../../hooks/useNavContext';
import useDeepLinkContext from '../../hooks/useDeepLinkContext';

import * as MerchantService from '../../api/merchantService';
import * as LoyaltyRewardService from '../../api/loyaltyRewardsService';
import * as StampCardService from '../../api/stampCardService';
import * as StampCampaignService from '../../api/stampCampaignService';
import * as RewardCardService from '../../api/rewardCardService';
import * as ReferralService from '../../api/referralService';
import * as StoreService from '../../api/storeService';
import * as ProfileService from '../../api/profileService';
import AddStampDialog from '../../components/Dialogs/AddStampDialog';
import AddStampSuccessDialog from '../../components/Dialogs/AddStampSuccessDialog';
import EditProfileDialog from '../../components/Dialogs/EditProfileDialog';
import SelectMerchantStoreDialog from '../../components/Dialogs/SelectMerchantStoreDialog';
import RewardRedemptionDialog from '../../components/Dialogs/RewardRedemptionDialog';
import RewardSuccessRedemptionDialog from '../../components/Dialogs/RewardSuccessRedemptionDialog';
import StoreLocationDialog from '../../components/Dialogs/StoreLocationDialog';
import Spinner from '../../components/Spinner';

interface RouteParams {
  merchantId: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  rewardTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  subText: {
    color: '#989AA2',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stampContainer: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(3),
  },
  boxContainer: {
    flex: 1,
    marginTop: 350,
  },
  showHistoryBox: {
    marginTop: -55,
    position: 'relative',
    right: -140,
  },
  showDetailButton: {
    color: theme.palette.text.secondary,
    padding: 5,
  },
}));

/**
 * Merchant
 */
export default function MerchantScreen() {
  const { merchantId } = useParams<RouteParams>();
  const classes = useStyles();
  const [merchant, setMerchant] = useState<MerchantService.Merchant | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [stampCard, setStampCard] = useState<StampCardService.StampCard | null>(
    null
  );
  const [milestones, setMilestones] = useState<
    LoyaltyRewardService.RedemptionMilestone[]
  >([]);
  const [rewardCardList, setRewardCardList] = useState<
    RewardCardService.RewardCard[]
  >([]);
  const [profile, setProfile] = useState<ProfileService.Profile | null>(null);
  const [storeList, setStoreList] = useState<StoreService.Store[]>([]);
  const [stampCampaign, setStampCampaign] =
    useState<StampCampaignService.StampCampaign | null>(null);
  const [launchEditProfileDialog, setLaunchEditProfileDialog] = useState(false);
  const [launchAddStampDialog, setLaunchAddStampDialog] = useState(false);
  const [storeMerchantDialog, setStoreMerchantDialog] = useState(false);
  const [rewardRedemptionDialog, setRewardRedemptionDialog] = useState(false);
  const [successRedemptionDialog, setSuccessRedemptionDialog] = useState(false);
  const [successStampDialog, setSuccessStampDialog] = useState(false);
  const [storeDialog, setStoreDialog] = useState(false);
  const [redeemedRewardCardList, setRedeemedRewardCardList] = useState<
    RewardCardService.RewardCard[]
  >([]);
  const [selectedRedeemRewardCardId, setSelectedRedeemRewardCardId] =
    useState('');
  const [selectedStorePINId, setSelectedStorePINId] = useState('');
  const [selectedReward, setSelectedReward] =
    useState<RewardCardService.RewardCard | null>(null);
  const [referral, setReferral] =
    useState<ReferralService.ReferralProgram | null>(null);
  const [referralLink, setReferralLink] =
    useState<ReferralService.Deeplink | null>(null);
  const [addedStamp, setAddedStamp] = useState(1);
  const [reqFullName, setReqFullName] = useState(false);
  const [reqEmail, setReqEmail] = useState(false);
  const [reqAltEmail, setReqAltEmail] = useState(false);
  const [reqMobNum, setReqMobNum] = useState(false);
  const [reqDOB, setReqDOB] = useState(false);
  const [reqCompany, setReqCompany] = useState(false);
  const [historyExpand, setHistoryExpand] = useState(false);

  const nav = useNavContext();
  const dl = useDeepLinkContext();
  dl?.removeDeeplink();

  const fetchRewardCard = useCallback(async () => {
    try {
      const redeemedRewardCards =
        await RewardCardService.listMerchantRedemeedRewardCards(merchantId);

      const collectedRewardCards =
        await RewardCardService.listMerchantCollectedRewardCards(merchantId);

      const expiredRewardCards =
        await RewardCardService.listMerchantExpiredRewardCards(merchantId);

      const now = parseInt(moment().format('X'), 10);

      setRewardCardList(
        filter(collectedRewardCards, function (card) {
          return card.expiresAt >= now;
        })
      );
      if (expiredRewardCards) {
        setRedeemedRewardCardList(
          redeemedRewardCards.concat(
            expiredRewardCards.concat(
              filter(collectedRewardCards, function (card) {
                return card.expiresAt < now;
              })
            )
          )
        );
      } else {
        redeemedRewardCards.concat(
          filter(collectedRewardCards, function (card) {
            return card.expiresAt < now;
          })
        );
      }
      setTimeout(() => setIsLoading(false), 1000);
    } catch (e) {
      console.log('error: ', e);
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [merchantId]);

  useEffect(() => {
    const fetch = async () => {
      const resProfile = await ProfileService.get();
      setProfile(resProfile);

      const res = await MerchantService.get(merchantId);
      setMerchant(res);

      const resStamp = await StampCardService.getByMerchantId(merchantId);
      setStampCard(resStamp);

      const resMilestone = await LoyaltyRewardService.listMilestone(
        resStamp.loyalty_campaign.id
      );
      setMilestones(resMilestone);

      const resCampaign = await StampCampaignService.get(
        resStamp.loyalty_campaign.id
      );
      setStampCampaign(resCampaign);

      const resStore = await StoreService.list(merchantId);
      setStoreList(resStore);

      setTimeout(() => fetchRewardCard(), 1000);
    };

    if (isLoading) {
      fetch();
    }
    if (nav) {
      nav.removeNav();
    }
  }, [merchantId, isLoading, nav, fetchRewardCard]);

  useEffect(() => {
    const fetchRedeemedRewardCard = async () => {
      const res = await RewardCardService.get(selectedRedeemRewardCardId);

      setSelectedReward(res);
    };

    if (selectedRedeemRewardCardId) {
      fetchRedeemedRewardCard();
    }
  }, [selectedRedeemRewardCardId]);

  useEffect(() => {
    const fetchReferral = async () => {
      try {
        const res = await ReferralService.get(merchantId);

        if (res) {
          setReferral(res);
        }
      } catch (e) {
        console.log('Error: ', e);
      }
    };

    if (merchantId) {
      fetchReferral();
    }
  }, [merchantId]);

  useEffect(() => {
    const fetchReferralLink = async () => {
      try {
        const res = await ReferralService.getDeepLink(merchantId);

        if (res) {
          setReferralLink(res);
        }
      } catch (e) {
        console.log('Error: ', e);
      }
    };

    if (merchantId) {
      fetchReferralLink();
    }
  }, [merchantId]);

  // Check if user profile is incomplete and merchant require some info
  useEffect(() => {
    if (merchant && profile && profile.profileCompletedStatus) {
      setIsLoading(true);
      if (
        merchant.requiredUserInfo.FullName &&
        profile.profileCompletedStatus.FullName === false
      ) {
        setReqFullName(true);
      }
      if (
        merchant.requiredUserInfo.Email &&
        profile.profileCompletedStatus.Email === false &&
        profile.mobNum.length > 0
      ) {
        setReqEmail(true);
      }
      if (
        merchant.requiredUserInfo.AltEmail &&
        profile.profileCompletedStatus.AltEmail === false &&
        profile.email.length > 0 &&
        profile.email.includes('@privaterelay.appleid.com')
      ) {
        setReqAltEmail(true);
      }
      if (
        merchant.requiredUserInfo.MobNum &&
        profile.profileCompletedStatus.MobNum === false &&
        profile.email.length > 0
      ) {
        setReqMobNum(true);
      }
      if (
        merchant.requiredUserInfo.DOB &&
        profile.profileCompletedStatus.DOB === false
      ) {
        setReqDOB(true);
      }
      if (
        merchant.requiredUserInfo.Company &&
        profile.profileCompletedStatus.Company === false
      ) {
        setReqCompany(true);
      }
      if (
        (merchant.requiredUserInfo.FullName &&
          profile.profileCompletedStatus.FullName === false) ||
        (merchant.requiredUserInfo.Email &&
          profile.profileCompletedStatus.Email === false &&
          profile.mobNum.length > 0) ||
        (merchant.requiredUserInfo.AltEmail &&
          profile.profileCompletedStatus.AltEmail === false &&
          profile.email.length > 0 &&
          profile.email.includes('@privaterelay.appleid.com')) ||
        (merchant.requiredUserInfo.MobNum &&
          profile.profileCompletedStatus.MobNum === false &&
          profile.email.length > 0) ||
        (merchant.requiredUserInfo.DOB &&
          profile.profileCompletedStatus.DOB === false) ||
        (merchant.requiredUserInfo.Company &&
          profile.profileCompletedStatus.Company === false)
      ) {
        setLaunchEditProfileDialog(true);
        setTimeout(() => setIsLoading(false), 1000);
      } else {
        setTimeout(() => setIsLoading(false), 1000);
      }
    }
  }, [
    merchant,
    profile,
    reqFullName,
    reqAltEmail,
    reqEmail,
    reqDOB,
    reqMobNum,
    reqCompany,
  ]);

  const handleAddStamp = () => {
    setLaunchAddStampDialog(true);
  };

  const setRedeemReward = (rewardCardId: string) => {
    setSelectedRedeemRewardCardId(rewardCardId);
    setStoreMerchantDialog(true);
  };

  const handleRedeemReward = async () => {
    try {
      const res = await RewardCardService.redeem(
        selectedRedeemRewardCardId,
        selectedStorePINId
      );
      if (res) {
        setRewardRedemptionDialog(false);
        setSuccessRedemptionDialog(true);
      }
    } catch (e) {
      console.log('error: e');
      setRewardRedemptionDialog(false);
    }
  };

  // if (
  //   isLoading ||
  //   !merchant ||
  //   !stampCard ||
  //   !stampCampaign ||
  //   storeList.length === 0
  // ) {
  //   return (
  //     <Box display="flex" justifyContent="center">
  //       <Spinner />
  //     </Box>
  //   );
  // }

  // if (!merchant) {
  //   return null;
  // }
  // if (!stampCard) {
  //   return null;
  // }

  // if (!stampCampaign) {
  //   return null;
  // }

  // if (storeList.length === 0) {
  //   return null;
  // }

  return (
    <>
      <div className={classes.container}>
        {isLoading && (
          <div className={classes.boxContainer}>
            <Box display="flex" justifyContent="center">
              <Spinner />
            </Box>
          </div>
        )}
        {!isLoading &&
          merchant &&
          stampCard &&
          stampCampaign &&
          storeList.length > 0 && (
            <>
              <div className={classes.stampContainer}>
                <StampCard
                  merchantId={merchant.id}
                  merchantName={merchant.name}
                  merchantCoverImage={merchant.cover?.versions.xl}
                  merchantLogo={merchant.logo?.versions.xl}
                  milestones={milestones}
                  maxStamps={stampCard.redemptionCost}
                  quantity={stampCard.quantity}
                  handleAddStamp={handleAddStamp}
                  stampCampaignType={stampCampaign.type}
                  stampCardStatus={stampCard.status}
                />
              </div>
              <Typography variant="h4" className={classes.rewardTitle}>
                Your Rewards
              </Typography>
              {rewardCardList.length > 0 ? (
                <RewardCard
                  rewardCardList={rewardCardList}
                  handleRedeemReward={setRedeemReward}
                />
              ) : (
                <Typography
                  variant="h6"
                  className={classes.subText}
                  gutterBottom
                >
                  Earned rewards will appear here
                </Typography>
              )}
              <Typography variant="h4" className={classes.rewardTitle}>
                History
              </Typography>
              {redeemedRewardCardList.length > 3 && (
                <>
                  <div className={classes.showHistoryBox}>
                    <Button
                      variant="text"
                      onClick={() => setHistoryExpand(!historyExpand)}
                      className={classes.showDetailButton}
                    >
                      {historyExpand ? 'Hide' : 'Show All'}
                    </Button>
                  </div>
                  {!historyExpand && (
                    <RedeemedRewardCard
                      rewardCardList={redeemedRewardCardList.slice(0, 3)}
                    />
                  )}
                  {historyExpand && (
                    <RedeemedRewardCard
                      rewardCardList={redeemedRewardCardList}
                    />
                  )}
                </>
              )}
              {redeemedRewardCardList.length > 0 &&
                redeemedRewardCardList.length <= 3 && (
                  <RedeemedRewardCard rewardCardList={redeemedRewardCardList} />
                )}
              {redeemedRewardCardList.length === 0 && (
                <Typography
                  variant="h6"
                  className={classes.subText}
                  gutterBottom
                >
                  Redeemed and expired rewards will appear here
                </Typography>
              )}
              {referral && referralLink && (
                <>
                  <Typography variant="h4" className={classes.rewardTitle}>
                    Refer a Friend
                  </Typography>
                  <ReferralCard
                    merchantId={merchantId}
                    merchantName={merchant.name}
                    joiningRewardId={referral.joiningReward.id}
                    referralRewardId={referral.referralReward.id}
                    handleCopyLink={() => console.log('handle copy link')}
                    link={referralLink.link}
                  />
                </>
              )}
              {storeList.length > 0 && (
                <>
                  <Typography variant="h4" className={classes.rewardTitle}>
                    Location{storeList.length > 1 ? 's' : ''}
                  </Typography>
                  <StoreCard
                    storeList={storeList}
                    handleDialog={() => setStoreDialog(true)}
                  />
                </>
              )}
              <Typography variant="h4" className={classes.rewardTitle}>
                About
              </Typography>
              <AboutCard about={merchant.about} link={merchant.url} />
              {storeList && (
                <StoreLocationDialog
                  storeList={storeList}
                  open={storeDialog}
                  onClose={() => setStoreDialog(false)}
                />
              )}
              <AddStampDialog
                stampCardId={stampCard.id}
                open={launchAddStampDialog}
                onClose={() => setLaunchAddStampDialog(false)}
                confirmRedeem={(addedStamp: number) => {
                  setAddedStamp(addedStamp);
                  setLaunchAddStampDialog(false);
                  setSuccessStampDialog(true);
                }}
              />
              <AddStampSuccessDialog
                open={successStampDialog}
                onClose={() => {
                  setSuccessStampDialog(false);
                  setIsLoading(true);
                }}
                campaignId={stampCard.loyalty_campaign.id}
                quantity={stampCard.quantity + addedStamp}
                isMultipleStamp={addedStamp > 1}
                stampAdded={addedStamp.toString()}
                handleAddMoreStamp={() => {
                  setSuccessStampDialog(false);
                  setLaunchAddStampDialog(true);
                }}
                allowAddMoreStamp={false}
              />
              <SelectMerchantStoreDialog
                open={storeMerchantDialog}
                onClose={() => setStoreMerchantDialog(false)}
                merchantId={merchantId}
                merchantLogo={merchant.logo?.versions.xl}
                confirmStore={(storePINId: string) => {
                  setSelectedStorePINId(storePINId);
                  setStoreMerchantDialog(false);
                  setRewardRedemptionDialog(true);
                }}
              />
              <RewardRedemptionDialog
                merchantLogo={merchant.logo?.versions.xl}
                open={rewardRedemptionDialog}
                onClose={() => setRewardRedemptionDialog(false)}
                rewardTitle={selectedReward ? selectedReward.title : ''}
                confirmRedemption={() => {
                  handleRedeemReward();
                }}
              />
              <RewardSuccessRedemptionDialog
                rewardTitle={selectedReward ? selectedReward.title : ''}
                open={successRedemptionDialog}
                onClose={() => {
                  setSuccessRedemptionDialog(false);
                  setSelectedRedeemRewardCardId('');
                  setSelectedStorePINId('');
                  setIsLoading(true);
                }}
              />
              {profile && merchant && (
                <EditProfileDialog
                  open={launchEditProfileDialog}
                  onClose={() => {
                    setLaunchEditProfileDialog(false);
                  }}
                  profile={profile}
                  confirmUpdate={(status: boolean) => {
                    if (status) {
                      setLaunchEditProfileDialog(false);
                    }
                  }}
                  requestFullName={reqFullName}
                  requestMobile={reqMobNum}
                  requestEmail={reqEmail}
                  requestAltEmail={reqAltEmail}
                  requestDOB={reqDOB}
                  requestCompany={reqCompany}
                  logo={merchant.logo!.versions.xl}
                />
              )}
            </>
          )}
      </div>
    </>
  );
}
