import request from './request';
import deserialize from './deserialize';

type Image = {
  id: string;
  versions: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
};

type RequiredUserInfo = {
  AltEmail: boolean;
  DOB: boolean;
  Email: boolean;
  FullName: boolean;
  MobNum: boolean;
  Company: boolean;
};

export type Merchant = {
  id: string;
  name: string;
  category: {
    key: string;
    label: string;
  };
  about: string;
  url: string;
  logo?: Image;
  cover?: Image;
  applink: string;
  country: string;
  requiredUserInfo: RequiredUserInfo;
};

export function get(merchantId: string) {
  return request<Merchant>({
    url: `/api/v3/merchants/${merchantId}`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function getByReferralCode(merchantCode: string) {
  return request<Merchant>({
    url: `/api/v3/merchants`,
    method: 'POST',
    data: {
      data: {
        attributes: {
          referralCode: merchantCode,
        },
      },
    },
    transformResponse: deserialize,
  });
}
