/**
 * @file Manages the deeplink storage in local storage
 */

export type Deeplink = {
  feature?: string;
  merchantId?: string;
  contactlessCode?: string;
  couponCode?: string;
  merchantCode?: string;
  userCode?: string;
  logo?: string;
  singleUseCode?: string;
  store?: string;
  stampNum?: string;
};

/**
 * Get the current deeplink
 */
export function get(): Deeplink | null {
  const deeplink = window.localStorage.getItem('deeplink');

  if (deeplink) {
    return JSON.parse(deeplink);
  }

  return null;
}

/**
 * Set the deeplink
 */
export function set(deeplink: Deeplink) {
  window.localStorage.setItem('deeplink', JSON.stringify(deeplink));
}

/**
 * Remove the deeplink
 */
export function remove() {
  window.localStorage.removeItem('deeplink');
}
