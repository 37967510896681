import React, { useState } from 'react';
import { Form, Formik, FormikHelpers, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as authService from '../../api/authService';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import Flexlogo from '../../assets/images/logo-red.png';

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      width: 400,
      margin: '0 auto',
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(),
    },
  },
  form: {
    marginTop: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(),
  },
  logoContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 140,
    height: 50,
  },
}));

interface Values {
  email: string;
}

const ValidationSchema = Yup.object().shape({
  email: Yup.string().required('Required').email('Not a valid email'),
});

const initialValues: Values = {
  email: '',
};

/**
 * SignIn displays the sign in page.
 */
export default function ForgotPassword() {
  const classes = useStyles();
  const [launchDialog, setLaunchDialog] = useState(false);
  const [message, setMessage] = useState('');
  const history = useHistory();

  const handleSubmit = async (
    values: Values,
    { setFieldError, setSubmitting }: FormikHelpers<Values>
  ) => {
    const { email } = values;

    try {
      // request reset password
      await authService.requestPasswordReset(email);

      setMessage(
        'Request for password reset is successful. Please check your email.'
      );
      setLaunchDialog(true);
    } catch (e: any) {
      e.data.forEach((err: any) => {
        setFieldError(err.meta.attribute, err.detail);
      });

      setSubmitting(false);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.content}>
            <div className={classes.logoContainer}>
              <img src={Flexlogo} className={classes.logo} alt="flex reward" />
            </div>

            <Typography variant="body1" gutterBottom>
              To reset your password, enter your email address and we’ll send
              you instructions on how to create a new password.
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={ValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form noValidate className={classes.form}>
                  <Field
                    component={TextField}
                    className={classes.textField}
                    name="email"
                    type="email"
                    autoFocus
                    fullWidth
                    variant="standard"
                    placeholder="Email"
                  />

                  <div className={classes.actions}>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                    >
                      Reset Password
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={launchDialog}
        onClose={() => setLaunchDialog(false)}
        onCancel={() => setLaunchDialog(false)}
        onConfirm={() => {
          setLaunchDialog(false);
          history.push('/login');
        }}
        title="Forgot Password"
        body={message}
        confirmButtonText="Return to login"
      />
    </>
  );
}
