import React from 'react';

import { ThemeProvider } from '@material-ui/core/styles';

import AppRouter from './components/AppRouter';
import { AuthContextProvider } from './context/authContext';
import { NavContextProvider } from './context/navContext';
import { DeepLinkContextProvider } from './context/deepLinkContext';
import { useGoogleMapsApi } from './hooks/useGoogleMapsApi';
import { GoogleOAuthProvider } from '@react-oauth/google';
import theme from './styles/theme';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY as string;
const googleWebClient = process.env.REACT_APP_GOOGLE_OAUTH_WEB_CLIENT as string;

function App() {
  useGoogleMapsApi(GOOGLE_API_KEY);
  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <GoogleOAuthProvider clientId={googleWebClient}>
          <NavContextProvider>
            <DeepLinkContextProvider>
              <AppRouter />
            </DeepLinkContextProvider>
          </NavContextProvider>
        </GoogleOAuthProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
