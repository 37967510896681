import { colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#EA0043',
    },
    secondary: {
      main: '#ffffff',
    },
    error: {
      main: '#EA0043',
    },
    action: {
      disabledBackground: colors.grey[200],
    },
    background: {
      default: '#ffffff',
    },
    text: {
      secondary: '#989AA2',
    },
  },
  typography: {
    fontFamily:
      "'ClarikaGeometric', 'Nunito Sans', 'Helvetica', 'Arial', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600, // This maps to Nunito Sans Semibold as it does not have a Medium.
    fontWeightBold: 700,
    h1: {
      fontWeight: 600,
      fontSize: 60,
      letterSpacing: -0.5,
      lineHeight: '80px',
    },
    h2: {
      fontWeight: 600,
      fontSize: 48,
      letterSpacing: -0.5,
      lineHeight: '72px',
    },
    h3: {
      fontWeight: 600,
      fontSize: 38,
      letterSpacing: -0.75,
      lineHeight: '56px',
    },
    h4: {
      fontWeight: 600,
      fontSize: 28,
      letterSpacing: -0.75,
      lineHeight: '32px',
    },
    h5: {
      fontWeight: 600,
      fontSize: 21,
      letterSpacing: 0,
      lineHeight: '28px',
    },
    h6: {
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: '21px',
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: '21px',
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '21px',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: '21px',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '21px',
    },
    caption: {
      color: '#989AA2',
      fontWeight: 600,
      fontSize: 11,
      letterSpacing: 0,
      lineHeight: '21px',
    },
  },
});

const overrideTheme = {
  ...theme,
  props: {
    // Name of the component ⚛️
    MuiButton: {
      variant: 'contained',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
        },
      },
    },
    MuiButton: {
      /* Styles applied to the root element if `variant="contained"`. */
      contained: {
        // Adjusting the bottom padding here due to Nunito Sans having more spacing
        // at the bottom than top of text.
        padding: '6px 22px',
        borderRadius: 20,
        boxShadow: 'none',
        backgroundColor: theme.palette.grey[200],
        fontSize: theme.typography.pxToRem(14),
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: theme.palette.grey[300],
        },
      },
      outlined: {
        borderRadius: 20,
      },
      /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
      containedPrimary: {
        boxShadow: '0 0 10px 0 rgba(186, 186, 186, 0.5)',
        '&:hover': {
          backgroundColor: 'rgba(213, 0, 61, 1.0)',
          boxShadow: '0 0 10px 0 rgba(144, 144, 144, 0.5)',
        },
      },
      /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
      containedSecondary: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        boxShadow: '0 0 10px 0 rgba(186, 186, 186, 0.5)',
        '&:hover': {
          backgroundColor: theme.palette.common.white,
          boxShadow: '0 0 10px 0 rgba(144, 144, 144, 0.5)',
        },
      },
      label: {
        textTransform: 'none',
      },
      /* Styles applied to the root element if `size="small"` and `variant="contained"`. */
      containedSizeSmall: {
        padding: '5px 10px 4px',
        fontSize: theme.typography.pxToRem(10),
      },
      /* Styles applied to the root element if `size="large"` and `variant="contained"`. */
      containedSizeLarge: {
        borderRadius: 22,
        fontSize: theme.typography.pxToRem(16),
      },
    },
    MuiFormHelperText: {
      error: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiInput: {
      root: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 2,
        backgroundColor: '#ffffff',
        fontSize: theme.typography.pxToRem(13),
      },
      input: {
        padding: theme.spacing(1.25),
        fontWeight: theme.typography.fontWeightMedium,
      },
      error: {
        borderColor: theme.palette.error.main,
      },
      formControl: {
        'label + &': {
          marginTop: theme.spacing(3),
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.black,
        width: '100%',
      },
      shrink: {
        transform: 'none',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          // increase the specificity for the pseudo class
          color: theme.palette.common.black,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiSwitch: {
      root: {
        width: 38,
        height: 22,
        padding: 0,
        margin: theme.spacing(1),
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(16px)',
          color: theme.palette.common.white,
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 20,
        height: 20,
        backgroundColor: theme.palette.common.white,
      },
      track: {
        borderRadius: 22 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.common.white,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
      },
    },
    MuiAutocomplete: {
      endAdornment: {
        right: theme.spacing(),
      },
      inputRoot: {
        '&[class*="MuiInput-root"]': {
          paddingBottom: 1,
          '& $input': {
            padding: 10,
          },
          '& $input:first-child': {
            padding: '10px',
          },
        },
      },
    },
    MuiTableContainer: {
      root: {
        marginTop: theme.spacing(3),
        border: `1px solid ${theme.palette.grey[300]}`,
        boxShadow: 'none',
        borderRadius: 8,
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '12px 16px',
        borderBottom: 'none',
      },
      head: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightBold,
      },
      body: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiInputAdornment: {
      root: {
        paddingLeft: theme.spacing(),
        '& .MuiTypography-root': {
          color: theme.palette.common.black,
          fontWeight: 600,
          fontSize: 14,
          letterSpacing: 0,
        },
      },
    },
  },
};

// Provide component default overrides and props
export default overrideTheme;
