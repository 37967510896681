import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import useAuthContext from '../../hooks/useAuthContext';

/**
 * A PrivateRoute redirects an unauthenticated user to the sign in page.
 */
export default function PrivateRoute({ children, ...rest }: RouteProps) {
  const auth = useAuthContext();

  return (
    <Route
      {...rest}
      render={(props) =>
        auth?.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
