import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DialogPaper from '../DialogPaper';

import StoreMap from '../Map/StoreMap';
import * as StoreService from '../../api/storeService';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    width: 100,
    margin: theme.spacing(),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  toolbar: {
    display: 'flex',
    paddingLeft: 12,
    paddingRight: 12,
  },
  box: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  close: {
    marginRight: 'auto',
  },
  actions: {
    marginLeft: 'auto',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
  },
  closeIcon: {
    color: theme.palette.common.black,
  },
  map: {
    marginBottom: theme.spacing(2),
    height: 400,
    width: '100%',
  },
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    transition: 'none',
  },
  tabsIndicator: {
    display: 'none',
  },
  tabRoot: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    minWidth: 110,
  },
  tabSelected: {
    color: theme.palette.primary.main,
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  storeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    cursor: 'pointer',
  },
  storeHeaderDialog: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(),
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 350,
  },
  callContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(),
  },
}));

type Props = Pick<DialogProps, 'open' | 'onClose'> & {
  store: StoreService.Store;
};

export default function ShowStoreDialog({ store, onClose, open }: Props) {
  const classes = useStyles();

  const handleClose = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperComponent={DialogPaper}
    >
      <>
        <AppBar className={classes.appBar} position="sticky">
          <Toolbar className={classes.toolbar}>
            <div className={classes.box}>
              <div className={classes.close}>
                <IconButton onClick={handleClose}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
            </div>
            <div className={classes.box}>
              <Typography variant="h6" className={classes.title}>
                {store.name}
              </Typography>
            </div>
            <div className={classes.box}>
              <div className={classes.actions}></div>
            </div>
          </Toolbar>
        </AppBar>
        <Container className={classes.cardContainer}>
          <StoreMap
            storeList={[store]}
            marker={{
              lat: store.coords.lat,
              lng: store.coords.lng,
            }}
            fromDialog
          />
        </Container>
      </>
    </Dialog>
  );
}
