import React, { useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DialogPaper from '../DialogPaper';

import Paper from '../Paper';
import StoreMap from '../Map/StoreMap';
import * as StoreService from '../../api/storeService';
import { ReactComponent as Phone } from '../../assets/icons/Phone.svg';
import ShowStoreDialog from './ShowStoreDialog';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    width: 100,
    margin: theme.spacing(),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  toolbar: {
    display: 'flex',
    paddingLeft: 12,
    paddingRight: 12,
  },
  box: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  close: {
    marginRight: 'auto',
  },
  actions: {
    marginLeft: 'auto',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
  },
  closeIcon: {
    color: theme.palette.common.black,
  },
  map: {
    marginBottom: theme.spacing(2),
    height: 400,
    width: '100%',
  },
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    transition: 'none',
  },
  tabsIndicator: {
    display: 'none',
  },
  tabRoot: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    minWidth: 110,
  },
  tabSelected: {
    color: theme.palette.primary.main,
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  storeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    cursor: 'pointer',
  },
  storeHeaderDialog: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(),
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 350,
  },
  callContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(),
  },
}));

type Props = Pick<DialogProps, 'open' | 'onClose'> & {
  storeList: StoreService.Store[];
};

export default function StoreLocationDialog({
  storeList,
  onClose,
  open,
}: Props) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [showSelectedStoreMap, setShowSelectedStoreMap] = useState(false);
  const [selectedStore, setSelectedStore] = useState<
    StoreService.Store | undefined
  >(undefined);

  const handleClose = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleStoreCall = (phoneNum: string | undefined) => {
    if (phoneNum && phoneNum.length > 0) {
      const a = document.createElement('a');
      a.href = 'tel://' + phoneNum;
      document.body.appendChild(a);
      a.click();
      if (a.parentNode) {
        a.parentNode.removeChild(a);
      }
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperComponent={DialogPaper}
    >
      <>
        <AppBar className={classes.appBar} position="sticky">
          <Toolbar className={classes.toolbar}>
            <div className={classes.box}>
              <div className={classes.close}>
                <IconButton onClick={handleClose}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
            </div>
            <div className={classes.box}>
              <Typography variant="h6" className={classes.title}>
                Locations
              </Typography>
            </div>
            <div className={classes.box}>
              <div className={classes.actions}></div>
            </div>
          </Toolbar>
          <Toolbar className={classes.tabContainer}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="Store Tabs"
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Map View" />
              <Tab label="List View" />
            </Tabs>
          </Toolbar>
        </AppBar>
        <Container className={classes.cardContainer}>
          {selectedTab === 0 && (
            <StoreMap
              storeList={storeList}
              marker={{
                lat: storeList[0].coords.lat,
                lng: storeList[0].coords.lng,
              }}
              fromDialog
            />
          )}
          {selectedTab === 1 && (
            <Paper className={classes.paperContainer}>
              {storeList.map((store, index) => (
                <div
                  className={classes.storeContainer}
                  key={index}
                  onClick={() => {
                    setSelectedStore(store);
                    setShowSelectedStoreMap(true);
                  }}
                >
                  <div className={classes.storeHeaderDialog}>
                    <Typography variant="body1">{store.name}</Typography>
                    <Typography variant="body1">{store.address}</Typography>
                  </div>
                  {store && store.phone && store.phone.length > 0 ? (
                    <IconButton
                      className={classes.callContainer}
                      onClick={() => handleStoreCall(store.phone)}
                    >
                      <SvgIcon component={Phone} viewBox="0 0 30 30" />
                    </IconButton>
                  ) : (
                    <div className={classes.grow} />
                  )}
                </div>
              ))}
              {selectedStore && (
                <ShowStoreDialog
                  open={showSelectedStoreMap}
                  onClose={() => setShowSelectedStoreMap(false)}
                  store={selectedStore}
                />
              )}
            </Paper>
          )}
        </Container>
      </>
    </Dialog>
  );
}
