import React from 'react';
import moment from 'moment';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DialogPaper from '../DialogPaper';

import * as ProfileService from '../../api/profileService';

export interface Values {
  altEmail: string;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    width: 100,
    margin: theme.spacing(),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  toolbar: {
    display: 'flex',
    paddingLeft: 12,
    paddingRight: 12,
  },
  box: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  close: {
    marginRight: 'auto',
  },
  actions: {
    marginLeft: 'auto',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
  },
  closeIcon: {
    color: theme.palette.common.black,
  },
}));

type Props = Pick<DialogProps, 'open' | 'onClose'> & {
  profile: ProfileService.Profile;
  confirmUpdate: (status: boolean) => void;
};

export default function EditAltEmailDialog({
  profile,
  onClose,
  open,
  confirmUpdate,
}: Props) {
  const containsPrivateRelayAddress = (value: string) =>
    value.includes('@privaterelay.appleid.com') ? true : false;
  const classes = useStyles();
  const ValidationSchema = Yup.object().shape({
    altEmail: Yup.string()
      .required('Required')
      .email('Not a valid alternate email')
      .test(
        'excludePrivateRelayAddress',
        'Should not contain private relay address',
        (value) => (value ? !containsPrivateRelayAddress(value) : false)
      ),
  });

  const initialValues: Values = {
    altEmail: profile.alt_email ? profile.alt_email : ' ',
  };

  const handleSubmit = async (
    values: Values,
    { setFieldError, setSubmitting }: FormikHelpers<Values>
  ) => {
    if (values.altEmail) {
      let dobStr: string | null = null;
      if (profile.dob) {
        dobStr = moment.unix(profile.dob).format('YYYY-MM-DD');
      }
      try {
        await ProfileService.update(
          profile.gender,
          dobStr,
          profile.firstName,
          profile.lastName,
          profile.email,
          profile.mobNum,
          values.altEmail,
          profile.company
        );
        confirmUpdate(true);
        setSubmitting(false);
      } catch (e: any) {
        console.log('Error', e);
        e.data.forEach((err: any) => {
          setFieldError(err.meta.attribute, err.detail);
        });
        setSubmitting(false);
      }
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperComponent={DialogPaper}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={false}
      >
        {({ isSubmitting, dirty }) => {
          return (
            <Form noValidate>
              <AppBar className={classes.appBar} position="sticky">
                <Toolbar className={classes.toolbar}>
                  <div className={classes.box}>
                    <div className={classes.close}>
                      <IconButton onClick={handleClose}>
                        <CloseIcon className={classes.closeIcon} />
                      </IconButton>
                    </div>
                  </div>
                  <div className={classes.box}>
                    <Typography variant="h6" className={classes.title}>
                      Edit Alternate Email
                    </Typography>
                  </div>
                  <div className={classes.box}>
                    <div className={classes.actions}></div>
                  </div>
                </Toolbar>
              </AppBar>
              <Container maxWidth="md">
                <div className={classes.cardContainer}>
                  <Field
                    component={TextField}
                    className={classes.textField}
                    name="altEmail"
                    label="Alternate Email"
                    type="email"
                    autoFocus
                    variant="standard"
                    inputProps={{ style: { textTransform: 'lowercase' } }}
                  />
                  <div className={classes.actionContainer}>
                    <Button
                      onClick={() => {
                        if (onClose) {
                          onClose({}, 'backdropClick');
                        }
                      }}
                      className={classes.buttonContainer}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className={classes.buttonContainer}
                      disabled={isSubmitting || !dirty}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
