import request from './request';
import deserialize from './deserialize';

export type Deeplink = {
  link: string;
};

export type ReferralProgram = {
  id: string;
  joiningValidityPeriodDays: number;
  referralValidityPeriodDays: number;
  status: string;
  joiningReward: {
    type: 'rewards';
    id: string;
  };
  joiningRewardTemplate: {
    type: 'reward_templates';
    id: string;
  };
  loyaltyProgram: {
    type: 'loyalty_programs';
    id: string;
  };
  referralReward: {
    type: 'rewards';
    id: string;
  };
  referralRewardTemplate: {
    type: 'reward_templates';
    id: string;
  };
};

export function get(merchantId: string) {
  return request<ReferralProgram>({
    url: `/api/v3/merchants/${merchantId}/referral_program`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function getDeepLink(merchantId: string) {
  return request<Deeplink>({
    url: `/api/v3/merchants/${merchantId}/referral_program/link`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function issueLoyaltyReferralProgram(
  userCode: string,
  merchantCode: string
) {
  return request<ReferralProgram>({
    url: `/api/v3/referral_program/issue`,
    method: 'POST',
    data: {
      data: {
        attributes: {
          merchantReferralCode: merchantCode,
          userReferralCode: userCode,
        },
      },
    },
    transformResponse: deserialize,
  });
}
