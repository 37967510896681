import request from './request';
import deserialize from './deserialize';

export type Coupon = {
  id: string;
  code: string;
  createdAt: number;
  expiresAt: number;
  redeemed: boolean;
  updatedAt: number;
  loyaltyCampaign: {
    type: 'loyalty_campaigns';
    id: string;
  };
};

export function get(couponCode: string) {
  return request<Coupon>({
    url: `/api/v3/coupons/${couponCode}`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function redeem(couponCode: string) {
  return request<Coupon>({
    url: `/api/v3/coupons/${couponCode}/redeem`,
    method: 'POST',
    transformResponse: deserialize,
  });
}
