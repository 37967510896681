import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as MerchantService from '../../api/merchantService';
import * as StampCampaignService from '../../api/stampCampaignService';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: 350,
    padding: 20,
  },
  cardContainer: {
    cursor: 'pointer',
    borderRadius: 15,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  avatar: {
    height: 80,
    width: 80,
    boxShadow: '0 2px 10px rgba(199, 199, 199, 0.5)',
    marginBottom: theme.spacing(2),
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = {
  merchantId: string;
  to: string;
  loyaltyCampaignId: string;
};

export default function LoyaltyCard({
  merchantId,
  to,
  loyaltyCampaignId,
}: Props) {
  const classes = useStyles();
  const [merchant, setMerchant] = useState<MerchantService.Merchant | null>(
    null
  );
  const [stampCampaign, setStampCampaign] =
    useState<StampCampaignService.StampCampaign | null>(null);

  useEffect(() => {
    const fetchMerchant = async () => {
      const res = await MerchantService.get(merchantId);

      setMerchant(res);
    };

    fetchMerchant();
  }, [merchantId]);

  useEffect(() => {
    const fetchStampCampaign = async () => {
      const res = await StampCampaignService.get(loyaltyCampaignId);

      setStampCampaign(res);
    };
    fetchStampCampaign();
  }, [loyaltyCampaignId]);

  if (!merchant) {
    return null;
  }

  if (!stampCampaign) {
    return null;
  }

  // if (stampCampaign.type === 'prize' && stampCampaign.status === 'inactive') {
  //   return null;
  // }
  return (
    <Grid item xs={12} className={classes.root}>
      <Card className={classes.cardContainer}>
        <CardActionArea to={to} component={Link}>
          <CardContent className={classes.contentContainer}>
            <Avatar
              alt={merchant.name}
              src={merchant.logo?.versions.xl}
              className={classes.avatar}
            />
            <Typography variant="h5" className={classes.title} gutterBottom>
              {merchant.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
