import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import GLogo from '../../assets/images/g-logo.png';

type Props = {
  onClick: () => void;
  disabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 20,
    height: 20,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  grow: {
    width: 10,
  },
}));

export default function GoogleButton({ onClick, disabled }: Props) {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      size="large"
      fullWidth
      onClick={onClick}
      disabled={disabled}
      className={classes.container}
    >
      <img src={GLogo} className={classes.logo} alt="google" />
      Log In with Google
      <div className={classes.grow} />
    </Button>
  );
}
