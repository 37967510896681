import request from './request';
import deserialize from './deserialize';

export type StampCard = {
  id: string;
  createdAt: number;
  quantity: number;
  redemptionCost: number;
  status: string;
  updatedAt: number;
  loyalty_campaign: {
    type: 'loyalty_campaigns';
    id: string;
  };
  merchant: {
    type: 'merchants';
    id: string;
  };
  reward: {
    data: {
      type: 'rewards';
      id: string;
    };
  };
};

export type StampCardList = StampCard[];

export function list() {
  return request<StampCardList>({
    url: '/api/v3/stamp_cards',
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function get(stampCardId: string) {
  return request<StampCard>({
    url: `/api/v3/stamp_cards/${stampCardId}`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function getByMerchantId(merchantId: string) {
  return request<StampCard>({
    url: `/api/v3/merchants/${merchantId}/stamp_card`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function creditStampCard(
  stampCardId: string,
  pinCode: string,
  stampNum: number
) {
  return request<StampCard>({
    url: `/api/v3/stamp_cards/${stampCardId}/credit`,
    method: 'PATCH',
    data: {
      data: {
        attributes: {
          pin: pinCode,
          numOfStamps: stampNum,
        },
      },
    },
    transformResponse: deserialize,
  });
}

export function create(merchantId: string) {
  return request<StampCard>({
    url: `/api/v3/merchants/${merchantId}/stamp_card`,
    method: 'POST',
    data: {
      data: {
        type: 'stamp_cards',
        attributes: {
          source: 'scan',
        },
      },
    },
    transformResponse: deserialize,
  });
}

export function creditStampCardContactless(
  contactlessCode: string
) {
  return request<StampCard>({
    url: `/api/v3/stamp_cards/credit/contactless`,
    method: 'PATCH',
    data: {
      data: {
        attributes: {
          code: contactlessCode,
        },
      },
    },
    transformResponse: deserialize,
  });
}

export function creditStampCardSingleUse(
  singleUseCode: string,
  storeId: string,
  numOfStamps: number,
  merchantId: string
) {
  return request<StampCard>({
    url: `/api/v3/merchants/${merchantId}/credit/single_use`,
    method: 'PATCH',
    data: {
      data: {
        attributes: {
          code: singleUseCode,
          storeID: storeId,
          numOfStamps,
        },
      },
    },
    transformResponse: deserialize,
  });
}
