import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import AppleLogo from '../../assets/images/apple.png';

type Props = {
  onClick: () => void;
  disabled: boolean;
};

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#000',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
  grow: {
    width: 10,
  },
}));

export default function AppleButton({ onClick, disabled }: Props) {
  const classes = useStyles();

  if (!onClick) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      size="large"
      fullWidth
      onClick={onClick}
      className={classes.container}
      disabled={disabled}
    >
      <img src={AppleLogo} className={classes.logo} alt="apple" />
      Log In with Apple
      <div className={classes.grow} />
    </Button>
  );
}
