import React from 'react';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import LogoGrey from '../../assets/images/logo-grey.png';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  textContainer: {
    width: 300,
  },
  text: {
    color: '#8b8b8b',
    textAlign: 'center',
  },
  link: {
    color: '#8b8b8b',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  logoContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 140,
    height: 50,
  },
  email: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(4),
    color: '#757575',
  },
}));

export default function AboutScreen() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.grow} />
      <div className={classes.container}>
        <Grid item xs={12}>
          <div className={classes.logoContainer}>
            <img src={LogoGrey} className={classes.logo} alt="profile" />
          </div>
          <div className={classes.textContainer}>
            <Typography variant="body2" className={classes.text} gutterBottom>
              Your program has been provided by Flex Rewards, a software
              engineering company dedicated to providing the best loyalty
              solutions for businesses.
            </Typography>
            <Typography variant="body2" className={classes.link} gutterBottom>
              Find out more about Flex Rewards on our official website at
              <br />
              <Link href={'https://myflexrewards.com'} target="blank">
                www.myflexrewards.com
              </Link>
            </Typography>
            <Typography variant="body2" className={classes.link} gutterBottom>
              Click to read our&nbsp;
              <Link href={'https://myflexrewards.com/terms/'} target="blank">
                Terms of Service
              </Link>{' '}
              &nbsp;and&nbsp;
              <Link href={'https://myflexrewards.com/privacy/'} target="blank">
                Privacy Policy
              </Link>
            </Typography>
            <Typography variant="body2" className={classes.link} gutterBottom>
              For support&nbsp;
              <Link href={'mailto:customerservice@myflexrewards.com'}>
                contact us.
              </Link>
            </Typography>
          </div>
        </Grid>
      </div>
    </>
  );
}
