import React from 'react';

import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import * as StoreService from '../../api/storeService';
import StoreMap from '../Map/StoreMap';
import Paper from '../Paper';

import PrizeDrawLocation from '../../assets/images/prize-draw-location.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 350,
  },
  storeHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  logoContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  storeList: StoreService.Store[];
  handleDialog: () => void;
};

export default function StoreCard({ storeList, handleDialog }: Props) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.root}>
      {storeList.length === 1 ? (
        <StoreMap
          storeList={storeList}
          marker={{
            lat: storeList[0].coords.lat,
            lng: storeList[0].coords.lng,
          }}
        />
      ) : (
        <Paper className={classes.cardContainer}>
          <div className={classes.logoContainer}>
            <img
              src={PrizeDrawLocation}
              alt="location"
              className={classes.imageContainer}
            />
            <Button
              className={classes.actionButton}
              onClick={() => handleDialog()}
            >
              View Locations
            </Button>
          </div>
        </Paper>
      )}
    </Grid>
  );
}
