import React, { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import ReactMarkdown from 'react-markdown';

import Avatar from '@material-ui/core/Avatar';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as StampIcon } from '../../assets/icons/Stamp.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/Check.svg';

import * as LoyaltyRewardService from '../../api/loyaltyRewardsService';
import * as RewardService from '../../api/rewardService';
import * as StampCampaignService from '../../api/stampCampaignService';
import * as StampCardService from '../../api/stampCardService';

import Paper from '../Paper';

const useStampStyles = makeStyles((theme) => ({
  root: ({ highlight }: { highlight: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: highlight ? 2 : 1,
    borderStyle: 'solid',
    borderColor: highlight
      ? theme.palette.primary.main
      : theme.palette.grey[300],
    width: 40,
    height: 40,
    borderRadius: 40 / 2,
  }),
  icon: {
    color: theme.palette.primary.main,
  },
  fillRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    width: 40,
    height: 40,
    borderRadius: 40 / 2,
    backgroundColor: theme.palette.primary.main,
  },
}));

export function Stamp({
  value,
  highlight,
  currentStamp,
  stampCampaignType,
}: {
  value: number;
  highlight: boolean;
  currentStamp: number;
  stampCampaignType: string;
}) {
  const classes = useStampStyles({ highlight });

  return (
    <>
      {value <= currentStamp ? (
        <div className={classes.fillRoot}>
          <SvgIcon
            component={CheckIcon}
            color="primary"
            className={classes.icon}
            viewBox={'0 0 30 30'}
          />
        </div>
      ) : (
        <div className={classes.root}>
          {highlight ? (
            <SvgIcon
              component={StampIcon}
              color="primary"
              className={classes.icon}
            />
          ) : (
            <Typography variant="subtitle2">{value}</Typography>
          )}
        </div>
      )}
    </>
  );
}

const useMilestoneStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  image: {
    width: 36,
    height: 36,
    marginRight: 10,
    borderRadius: 2,
    objectFit: 'cover',
  },
  content: {
    minWidth: 0, // Ensure the child text overflow ellipsis works on a flex child
  },
  stamps: {
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

/**
 * Milestone renders the milestone list item
 * @param milestone
 */
export function MilestoneListItem({
  milestone,
}: {
  milestone: LoyaltyRewardService.RedemptionMilestone;
}) {
  const classes = useMilestoneStyles();
  const [reward, setReward] = useState<RewardService.Reward | null>(null);

  useEffect(() => {
    const fetchMilestoneReward = async () => {
      const res = await RewardService.get(milestone.reward.id);
      setReward(res);
    };

    fetchMilestoneReward();
  }, [milestone]);

  if (!reward) {
    return null;
  }

  return (
    <div className={classes.root}>
      {reward && reward.images && reward.images[0].versions.xl && (
        <div>
          <img
            src={reward.images[0].versions.xl}
            alt={reward.title}
            className={classes.image}
          />
        </div>
      )}
      <div style={{ minWidth: 0 }}>
        <Typography variant="subtitle2" className={classes.stamps}>
          {milestone.threshold} Stamps = {reward.title}
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 16px',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 350,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  avatar: {
    height: 80,
    width: 80,
    boxShadow: '0 2px 10px rgba(199, 199, 199, 0.5)',
    marginTop: -40,
    marginBottom: theme.spacing(2),
  },
  stampCardRoot: {
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(2.5)}px ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(),
    },
  },
  tile: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  milestonesContainer: {
    marginTop: '5px',
  },
  minSpend: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  subtext: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    lineHeight: '12px',
  },
  subtextPrize: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    lineHeight: '12px',
  },
  minSpendPrize: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  noCardText: {
    fontSize: 16,
    fontWeight: 800,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    padding: theme.spacing(5),
  },
  descContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px',
  },
  descText: {
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'center',
  },
  subtextContainerPrize: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  subtextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  markdown: {
    fontFamily: 'ClarikaGeometric',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
  },
  cardMedia: {
    height: 220,
    width: 350,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    backgroundSize: 'cover',
  },
}));

type Props = {
  merchantId: string;
  merchantName: string;
  merchantCoverImage?: string;
  merchantLogo?: string;
  maxStamps: number;
  milestones: LoyaltyRewardService.RedemptionMilestone[];
  quantity: number;
  handleAddStamp: () => void;
  stampCampaignType: string;
  stampCardStatus: string;
};

export default function StampCard({
  merchantId,
  merchantName,
  merchantCoverImage,
  merchantLogo,
  maxStamps,
  milestones,
  quantity,
  handleAddStamp,
  stampCampaignType,
  stampCardStatus,
}: Props) {
  const classes = useStyles();
  const highlightedKeys = milestones.map((m) => m.threshold);
  const [stampCampaign, setStampCampaign] =
    useState<StampCampaignService.StampCampaign | null>(null);
  const [stampCard, setStampCard] = useState<StampCardService.StampCard | null>(
    null
  );

  useEffect(() => {
    const fetchStampCard = async () => {
      if (merchantId) {
        const resStampCard = await StampCardService.getByMerchantId(merchantId);
        setStampCard(resStampCard);
      }
    };

    if (merchantId) {
      fetchStampCard();
    }
  }, [merchantId]);

  useEffect(() => {
    const fetchStampCampaign = async () => {
      if (
        stampCard &&
        stampCard.loyalty_campaign &&
        stampCard.loyalty_campaign.id
      ) {
        const res = await StampCampaignService.get(
          stampCard.loyalty_campaign.id
        );
        setStampCampaign(res);
      }
    };

    fetchStampCampaign();
  }, [stampCard]);

  if (!stampCard) {
    return null;
  }

  if (!stampCampaign) {
    return null;
  }

  return (
    <Grid item xs={12} className={classes.root}>
      <Paper
        className={classes.cardContainer}
        onClick={() => {
          if (stampCardStatus === 'active') {
            handleAddStamp();
          }
        }}
      >
        <CardMedia className={classes.cardMedia} image={merchantCoverImage} />
        <Avatar
          alt={merchantName}
          src={merchantLogo}
          className={classes.avatar}
        />
        <Typography variant="h5" className={classes.title} gutterBottom>
          {merchantName}
        </Typography>
        <div className={classes.stampCardRoot}>
          {stampCardStatus === 'inactive' ? (
            <Typography className={classes.noCardText} gutterBottom>
              No active card
            </Typography>
          ) : (
            <>
              <ImageList
                rowHeight={40}
                cols={maxStamps < 5 ? maxStamps : 5}
                gap={16}
                className={classes.tile}
              >
                {range(1, maxStamps + 1).map((x) => {
                  return (
                    <ImageListItem
                      key={x}
                      cols={1}
                      rows={1}
                      className={classes.tile}
                    >
                      <Stamp
                        key={x}
                        value={x}
                        highlight={highlightedKeys.includes(x) && x > quantity}
                        currentStamp={quantity}
                        stampCampaignType={stampCampaignType}
                      />
                    </ImageListItem>
                  );
                })}
              </ImageList>
              {stampCampaign && stampCampaign.description.length > 0 && (
                <div className={classes.descContainer}>
                  <ReactMarkdown className={classes.markdown}>
                    {stampCampaign.description}
                  </ReactMarkdown>
                </div>
              )}
              {milestones.length > 0 && (
                <div className={classes.milestonesContainer}>
                  {orderBy(milestones, ['threshold']).map((m, idx) => (
                    <MilestoneListItem key={idx} milestone={m} />
                  ))}
                </div>
              )}
              {stampCampaign.details.length > 0 &&
                stampCardStatus === 'active' && (
                  <Typography
                    className={
                      milestones.length > 0
                        ? classes.minSpend
                        : classes.minSpendPrize
                    }
                    gutterBottom
                  >
                    {stampCampaign.details.split(' ').slice(0, -1).join(' ')}
                  </Typography>
                )}
            </>
          )}
        </div>
      </Paper>
    </Grid>
  );
}
