import request from './request';
import deserialize from './deserialize';

export type Store = {
  id: string;
  address: string;
  coords: {
    lat: number;
    lng: number;
  };
  name: string;
  neighbourhood: string;
  phone?: string;
  pin: string;
  merchant: {
    type: 'merchants';
    id: string;
  };
};

export function list(merchantId: string) {
  return request<Store[]>({
    url: `/api/v3/merchants/${merchantId}/stores`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function getStoreByContactlessCode(contactlessCode: string) {
  return request<Store>({
    url: `/api/v3/stores/contactless/${contactlessCode}`,
    method: 'GET',
    transformResponse: deserialize,
  });
}
